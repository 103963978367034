import { GET_ORDERS_PART } from '../../types';
import axios from 'axios';
import { loadingDataComplete } from '../../uiActions';
import { getLocalStorage } from '../../../../hooks/useLocalStorage';
import { handleApiErrors } from '../../../../utils/handleApiErrors';

export const getOrdersSequentially = (dispatch, batchedQueries, current, total, selectedStatus) => {
  const selectedFilter = getLocalStorage('selectedFilter').replace(/['"]+/g, '');

  if (selectedFilter == selectedStatus) {
    const token = getLocalStorage('id_token');
    const next = current + 4;
    const slice = batchedQueries.slice(current, next);

    axios
      .all(
        slice.map((query) =>
          axios.get(query, {
            method: 'get',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          }),
        ),
      )
      .then((responses) => {
        const selectedFilter = getLocalStorage('selectedFilter').replace(/['"]+/g, '');

        if (selectedFilter == selectedStatus) {
          // Extract and combine batched data into one object
          const data = {
            items: responses
              .map((response) => {
                return response.data.items;
              })
              .flat(),
          };
          // Set order.customer to customer name and last name
          data.items.forEach((order) => {
            order.customer = `${order.customer_firstname} ${order.customer_lastname}`;
            order.total = order.base_grand_total?.toFixed(3);
          });

          // Dispatch data
          dispatch({ type: GET_ORDERS_PART, data, total });

          if (next <= total) {
            getOrdersSequentially(dispatch, batchedQueries, next, total, selectedStatus);
          } else {
            dispatch(loadingDataComplete(true));
          }
        }
      })
      .catch((error) => handleApiErrors(error));
  }
};
