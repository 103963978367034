import React, { useState } from 'react';
import { Paper, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import { MoreVert as MoreIcon } from '@material-ui/icons';
import classnames from 'classnames';

// styles
import useStyles from './styles';

export default function CustomWidget({
  children,
  title,
  id,
  option,
  noBodyPadding,
  bodyClass,
  disableWidgetMenu,
  header,
  ...props
}) {
  var classes = useStyles();

  // local
  var [moreButtonRef, setMoreButtonRef] = useState(null);
  var [isMoreMenuOpen, setMoreMenuOpen] = useState(false);

  return (
    <div className={classes.widgetWrapper}>
      <Paper className={classes.paper} classes={{ root: classes.widgetRoot }}>
        <div className={classes.widgetHeader}>
          {header ? (
            header
          ) : (
            <React.Fragment>
              <Typography variant="h4" className={classes.WidgetTitle}>
                {title}
              </Typography>
              {!disableWidgetMenu && (
                <Typography variant="h5" className={classes.WidgetId}>
                  {id}
                </Typography>
              )}
            </React.Fragment>
          )}
        </div>
        <div>
          <Typography variant="h5" className={classes.WidgetOption}>
            {option}
          </Typography>
        </div>
        <div
          className={classnames(classes.widgetBody, {
            [classes.noPadding]: noBodyPadding,
            [bodyClass]: bodyClass,
          })}
        >
          {children}
        </div>
      </Paper>
      <Menu
        id="widget-menu"
        open={isMoreMenuOpen}
        anchorEl={moreButtonRef}
        onClose={() => setMoreMenuOpen(false)}
        disableAutoFocusItem
      >
        <MenuItem>
          <Typography>Edit</Typography>
        </MenuItem>
        <MenuItem>
          <Typography>Copy</Typography>
        </MenuItem>
        <MenuItem>
          <Typography>Delete</Typography>
        </MenuItem>
        <MenuItem>
          <Typography>Print</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}
