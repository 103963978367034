import {
  SELECT_CATEGORIE,
  LOAD_CATEGORIES,
  LOAD_MORE_CATEGORIES,
  CHANGE_PAGE,
  LOAD_DATA_POINTS,
  CHANGE_DATA_POINTS_PAGE,
  CHANGE_DATA_POINTS_ROWS_PER_PAGE,
} from './types';
import axios from 'axios';
import { loadingData, loadingSec } from './uiActions';
import _ from 'lodash';
import { getLocalStorage } from '../../hooks/useLocalStorage';
import { handleApiErrors } from '../../utils/handleApiErrors';

const baseUrl = process.env.REACT_APP_BASE_URL;

const token = getLocalStorage('id_token');

export const changeDataPointsRowsPerPage = (rowsPerPage, categorie) => {
  return (dispatch) => {
    dispatch({ type: CHANGE_DATA_POINTS_ROWS_PER_PAGE, rowsPerPage });
    dispatch(loadDataPoints(0, rowsPerPage, categorie));
  };
};
export const changeDataPointsPage = (page, rows, categorie) => {
  return (dispatch) => {
    dispatch({ type: CHANGE_DATA_POINTS_PAGE, page });
    dispatch(loadDataPoints(page, rows, categorie));
  };
};
export const loadDataPoints = (currentPage, rowsPerPage, categorie) => {
  return (dispatch) => {
    dispatch(loadingSec(true));
    let cat = categorie === 0 ? '' : categorie;
    let query = `kamioun-pricing/pricing/search?searchCriteria[filterGroups][0][filters][0][condition_type]=like&searchCriteria[filterGroups][0][filters][0][field]=category&searchCriteria[filterGroups][0][filters][0][value]=%${cat}%&searchCriteria[pageSize]=${rowsPerPage}&searchCriteria[currentPage]=${
      currentPage + 1
    }`;
    axios
      .get(baseUrl + query, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let data = response.data;
        dispatch({ type: LOAD_DATA_POINTS, data });
        dispatch(loadingSec(false));
      })
      .catch((error) => handleApiErrors(error));
  };
};
export const changePage = (page) => {
  return { type: CHANGE_PAGE, page };
};
export const loadCategories = (loadMore, currentPage, itemsPerPage) => {
  return (dispatch) => {
    dispatch(loadingData(true));
    let query = `categories/list?searchCriteria[pageSize]=${itemsPerPage}&searchCriteria[currentPage]=${
      currentPage + 1
    }`;
    axios
      .get(baseUrl + query, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        const categories = response.data;
        const dataFiltered = [];
        categories.items.forEach((category) => {
          if (category.name !== 'Root Catalog' && category.name !== 'Default Category') {
            category.name = category.name.trim();
            dataFiltered.push(category);
          }
        });
        const data = _.uniqBy(dataFiltered, 'name');
        //dispatch(selectCategorie(response.data.items[0].id));

        if (loadMore) {
          dispatch({ type: LOAD_MORE_CATEGORIES, data });
          dispatch(changePage(currentPage));
        } else {
          dispatch({ type: LOAD_CATEGORIES, data });
        }
        dispatch(loadingData(false));
      })
      .catch((error) => handleApiErrors(error));
  };
};

export const selectCategorie = (categorieId) => {
  return { type: SELECT_CATEGORIE, categorieId };
};
