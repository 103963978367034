import { useEffect, useState } from 'react';

export const useLocalStorage = (key) => {
  const [value, setValue] = useState(() => {
    try {
      const storedValue = window.localStorage.getItem(key);
      return storedValue || undefined;
    } catch (error) {
      return undefined;
    }
  });

  useEffect(() => {
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {}
  }, [key, value]);

  return [value, setValue];
};

export const getLocalStorage = (key) => {
  try {
    if (key == 'id_token') {
      return 'pd2as4cqycmj671bga4egknw2csoa9b6';
    }

    const storedValue = window.localStorage.getItem(key);
    return storedValue || undefined;
  } catch (error) {
    return undefined;
  }
};
