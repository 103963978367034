import {
  GET_RETAILERS,
  SELECT_RETAILER,
  CLEAR_RETAILER,
  CREATE_RETAILER,
  GET_AGENTS,
} from './types';
import axios from 'axios';
import { loadingData } from './uiActions';
import { getLocalStorage } from '../../hooks/useLocalStorage';
import { handleApiErrors } from '../../utils/handleApiErrors';

const baseUrl = process.env.REACT_APP_BASE_URL;

const token = getLocalStorage('id_token');

export const getAgents = (callback) => {
  return (dispatch) => {
    dispatch(loadingData(true));
    axios
      .get(
        baseUrl +
          'customers/search/?searchCriteria[filterGroups][0][filters][0][field]=group_id&searchCriteria[filterGroups][0][filters][0][value]=4',
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        let data = response.data;
        dispatch({ type: GET_AGENTS, data });
        callback(data);
        dispatch(loadingData(false));
      })
      .catch((error) => handleApiErrors(error));
  };
};
export const getRetailers = () => {
  return (dispatch) => {
    dispatch(loadingData(true));
    axios
      .get(
        baseUrl +
          'customers/search/?searchCriteria[filterGroups][0][filters][0][field]=group_id&searchCriteria[filterGroups][0][filters][0][value]=3',
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        let data = response.data;
        dispatch({ type: GET_RETAILERS, data });
        dispatch(loadingData(false));
      })
      .catch((error) => handleApiErrors(error));
  };
};

export const createRetailers = (retailerData, location) => {
  const email_firstname = retailerData.firstname.replace(/\s+/g, '');
  const email_lastname = retailerData.lastname.replace(/\s+/g, '');
  let data = JSON.stringify({
    customer: {
      group_id: 3,
      email: `${email_firstname}.${email_lastname}@gmail.com`,
      firstname: retailerData.firstname,
      lastname: retailerData.lastname,
      store_id: 1,
      addresses: [
        {
          region: {
            region: location.region,
          },
          country_id: 'TN',
          street: [location.street],
          postcode: location.postalCode,
          city: 'Tunis',
          telephone: retailerData.phone,
          firstname: retailerData.firstname,
          lastname: retailerData.lastname,
        },
      ],
      extension_attributes: {
        zone: retailerData.zone,
        latitude: location.latitude,
        logitude: location.longitude,
      },
    },
  });
  return (dispatch) => {
    const options = {
      method: 'post',
      url: baseUrl + 'customers',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
      data: data,
    };
    axios(options)
      .then((response) => {
        let data = response.data;
        dispatch({ type: CREATE_RETAILER, data });
        dispatch(getRetailers());
      })
      .catch((error) => handleApiErrors(error));
  };
};

export const selectRetailer = (retailer) => {
  return { type: SELECT_RETAILER, retailer };
};

export const clearRetailer = () => {
  return { type: CLEAR_RETAILER };
};
