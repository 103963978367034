import axios from 'axios';
import { handleApiErrors } from '../../../../utils/handleApiErrors';

export const getFirstOrdersPage = async (selectedStatus, baseUrl, token) => {
  const requestQuery =
    selectedStatus !== 'allStatuses'
      ? `orders?searchCriteria[filterGroups][0][filters][0][field]=status&searchCriteria[filterGroups][0][filters][0][value]=${selectedStatus}&searchCriteria[filterGroups][0][filters][0][conditionType]=eq&searchCriteria[sortOrders][0][field]=entity_id&searchCriteria[sortOrders][0][direction]=DESC&searchCriteria[pageSize]=100&searchCriteria[currentPage]=1`
      : `orders?searchCriteria[pageSize]=100&searchCriteria[currentPage]=1&searchCriteria[sortOrders][0][field]=entity_id&searchCriteria[sortOrders][0][direction]=DESC`;
  try {
    const response = await axios.get(
      baseUrl + requestQuery,

      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      },
    );

    return response.data;
  } catch (error) {
    handleApiErrors(error);
  }
};
