import { makeStyles } from '@material-ui/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

export default makeStyles((theme) => ({
  /**Products Listing */
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  searchIcon: {
    right: theme.spacing(1.25),
    width: 36,
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: theme.transitions.create('right'),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    height: 36,
    padding: 0,
    paddingRight: 36 + theme.spacing(1.25),
    width: '100%',
  },
  search: {
    position: 'relative',
    borderRadius: 25,
    paddingLeft: theme.spacing(2.5),
    backgroundColor: fade(theme.palette.common.black, 0.04),
    width: '70%',
    marginBottom: theme.spacing(8),
  },
  searchIc: {
    color: 'rgba(0,0,0,0.2)',
  },

  /**New Order form  */
  totalValue: {
    fontWeight: 'bold',
  },
  total: {
    color: 'rgba(0,0,0,0.4)',
  },
  badgeCell: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  inputPriceTable: {
    minWidth: theme.spacing(4),
  },
  tableQteBadge: {
    backgroundColor: 'rgba(0,0,0,0.2)',
    color: '#ffffff',
  },
  oTable: {
    width: 'auto',
    tableLayout: 'fixed',
  },
  borderlessCell: {
    border: 'none',
  },
  borderedCell: {
    borderTop: '1px solid rgba(0,0,0,0.1)',
    borderBottom: 'none',
  },
  orderForm: {
    margin: theme.spacing(3),
  },
  btnGroup: {
    marginTop: theme.spacing(4),
  },
  button: {
    margin: theme.spacing(2),
  },
  btnPrimary: {
    backgroundColor: '#FFC260',
    margin: theme.spacing(2),
    '&:hover': {
      background: '#ffffff',
      color: '#FFC260',
    },
  },
  skusTitle: {
    marginTop: theme.spacing(4),
    fontWeight: 'bold',
    color: 'rgba(0,0,0,0.6)',
  },
  loadingCreation: {
    marginRight: 50,
  },
}));
