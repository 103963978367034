import React from 'react';
import axios from 'axios';
import { handleApiErrors } from '../utils/handleApiErrors';

const baseUrl = process.env.REACT_APP_BASE_URL;

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return { ...state, isAuthenticated: true };
    case 'SIGN_OUT_SUCCESS':
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem('id_token'),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>{children}</UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider');
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider');
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };

async function loginUser(dispatch, login, password, history, setIsLoading, setError) {
  setError(false);
  setIsLoading(true);

  if (!!login && !!password) {
    const credentials = JSON.stringify({
      username: login,
      password: password,
    });
    await axios({
      method: 'post',
      url: baseUrl + 'integration/admin/token',
      headers: {
        'Content-Type': 'application/json',
      },
      data: credentials,
    })
      .then((response) => {
        let data = response.data;
        if (data && data.length > 0) {
          localStorage.setItem('id_token', data);
          localStorage.setItem('username', login);
          setError(null);
          setIsLoading(false);
          dispatch({ type: 'LOGIN_SUCCESS' });
          history.push('/app/dashboard');
        } else {
          dispatch({ type: 'LOGIN_FAILURE' });
          setError(true);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setError(true);
        setIsLoading(false);
        handleApiErrors(error);
      });
  } else {
    dispatch({ type: 'LOGIN_FAILURE' });
    setError(true);
    setIsLoading(false);
  }
}

function signOut(dispatch, history) {
  localStorage.removeItem('id_token');
  localStorage.removeItem('username');

  dispatch({ type: 'SIGN_OUT_SUCCESS' });
  history.push('/login');
}
