import {
  GET_PRODUCTS,
  CHANGE_PRODUCTS_PAGE,
  SET_ITEMS_PER_PAGE,
  GET_PRODUCT_BY_ORDER,
  SEARCH_PRODUCT,
  LOADING_PRODUCTS,
  GET_ALL_PRODUCTS,
  GET_PRODUCTS_BY_LIST_SKU,
  GET_PRODUCTS_BY_CATEGORY,
  GET_CATEGORIES,
  GET_PRODUCTS_TO_SHIP,
  GET_ORDER_PRODUCTS,
  CLEAR_ORDER_PRODUCTS,
} from '../actions/types';

const initialState = {
  products: [],
  productsByOrder: [],
  productsToShip: false,
  productsByCategory: [],
  categories: [],
  page: 0,
  rowsPerPage: 5,
  total: 0,
  orderProducts: false,
  searchCriteria: '',
  isLoading: true,
  allProducts: [],
  filteredProducts: [],
};

export const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTS:
      return { ...state, products: action.products, total: action.total };

    case GET_PRODUCT_BY_ORDER:
      return { ...state, productsByOrder: action.products };
    case GET_PRODUCTS_TO_SHIP:
      return { ...state, productsToShip: action.products };
    case GET_ORDER_PRODUCTS:
      return { ...state, orderProducts: action.products };
    case CLEAR_ORDER_PRODUCTS:
      return { ...state, orderProducts: false };
    case GET_CATEGORIES:
      return { ...state, categories: action.data };

    case GET_PRODUCTS_BY_CATEGORY:
      return { ...state, productsByCategory: action.products, total: action.total };

    case CHANGE_PRODUCTS_PAGE:
      return { ...state, page: action.page };

    case SET_ITEMS_PER_PAGE:
      return { ...state, rowsPerPage: action.itemsNb };

    case SEARCH_PRODUCT:
      return { ...state, searchCriteria: action.searchCriteria };

    case LOADING_PRODUCTS:
      return { ...state, isLoading: action.isLoading };

    case GET_ALL_PRODUCTS:
      return { ...state, allProducts: action.data };

    case GET_PRODUCTS_BY_LIST_SKU:
      return { ...state, filteredProducts: action.data };
  }
  return state;
};
