import { makeStyles } from '@material-ui/styles';
import React from 'react';

export default makeStyles((theme) => ({
  loadingCircle: {
    display: 'flex',
    justifyContent: 'center',
    margin: '50px',
  },
  containerPaper: {
    margin: theme.spacing(2),
    minHeight: 500,
    width: '100%',
  },
  box: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignSelf: 'flew-end',
    width: '100%',
    marginTop: -20,
  },
  btn_filter: {
    margin: '2%',
    borderRadius: 50,
    fontSize: 13,
    textTransform: 'capitalize',
  },
  doneIcon: {
    height: 20,
    width: 20,
    marginTop: 0,
    marginBottom: -5,
    marginLeft: 10,
  },
  categoryName: {
    textTransform: 'capitalize',
    color: '#5c5d5e',
    fontWeight: 'bold',
    fontSize: 30,
  },
  productQty: {
    backgroundColor: '#c8f4f7',
    fontSize: 20,
    color: '#1569ad',
  },
  priceField: {
    color: '#f0bf2b',
  },
  formButtons: {
    float: 'right',
  },
  validateButton: {
    backgroundColor: '#FFC260',
    color: 'white',
    fontWeight: 'bold',
    margin: theme.spacing(2),
    '&:hover': {
      background: '#ffffff',
      color: '#FFC260',
      fontWeight: 'bold',
    },
  },
  cancelButton: {
    backgroundColor: 'white',
    color: 'grey',
    fontWeight: 'bold',
    margin: theme.spacing(2),
    '&:hover': {
      background: '#bfbfba',
      color: '#636360',
      fontWeight: 'bold',
    },
  },
  addButton: {
    margin: 20,
    fontSize: 13,
  },
  retailerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  orderDetails: {
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.4)',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[5],
    borderRadius: 14,
    padding: theme.spacing(2, 4, 3),
    height: 'auto',
    width: '70%',
    outline: 'none',
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    marginRight: 10,
  },
  title: {
    margin: theme.spacing(1),
    display: 'flex',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  addressDivider: {
    height: 40,
    color: '#aaabad',
  },
}));
