import { CLEAR_ORDERS, GET_ORDERS_PART } from '../../types';
import { loadingData, loadingDataComplete } from '../../uiActions';
import { getOrdersBatchQueries } from './getOrdersBatchQueries';
import { getLocalStorage } from '../../../../hooks/useLocalStorage';
import { getOrdersSequentially } from './getOrdersSequentially';
import { handleApiErrors } from '../../../../utils/handleApiErrors';
import { getFirstOrdersPage } from './getFirstOrdersPage';

const baseUrl = process.env.REACT_APP_BASE_URL;

export const getOrders = (selectedStatus) => {
  const token = getLocalStorage('id_token');

  return async (dispatch) => {
    // Update loading data to true to display spinner
    dispatch(loadingData(true));
    dispatch(loadingDataComplete(false));

    try {
      const firstOrdersPage = await getFirstOrdersPage(selectedStatus, baseUrl, token);
      dispatch({ type: CLEAR_ORDERS });
      firstOrdersPage.items.forEach((order) => {
        order.customer = `${order.customer_firstname} ${order.customer_lastname}`;
        order.base_grand_total !== null
            ? order.total = order.base_grand_total.toFixed(3)
            : order.total = order.base_grand_total;
      });
      const data = { items: firstOrdersPage.items };
      const total = firstOrdersPage.total_count;
      dispatch({ type: GET_ORDERS_PART, data, total });
      dispatch(loadingData(false));
      dispatch(loadingDataComplete(true));

      if (total > 100) {
        // dispatch(loadingData(true));
        dispatch(loadingDataComplete(false));

        const batchedQueries = getOrdersBatchQueries(100, total, selectedStatus, baseUrl, token);
        getOrdersSequentially(dispatch, batchedQueries, 1, batchedQueries.length, selectedStatus);
      }
    } catch (error) {
      handleApiErrors(error);
    }
  };
};
