import { GET_MANUFACTURERS } from './types';
import axios from 'axios';
import { loadingData } from './uiActions';

import { getLocalStorage } from '../../hooks/useLocalStorage';
import { handleApiErrors } from '../../utils/handleApiErrors';

const baseUrl = process.env.REACT_APP_BASE_URL;

const token = getLocalStorage('id_token');

export const getManufacturers = (callback) => {
  return async (dispatch) => {
    dispatch(loadingData(true));
    await axios
      .get(baseUrl + 'kamioun-manufacturers/manufacturer/search?searchCriteria=', {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let data = response.data;
        //data = getBlsByManufacturer(data);
        dispatch({ type: GET_MANUFACTURERS, data });
        dispatch(loadingData(false));
        callback(data);
      })
      .catch((error) => handleApiErrors(error));
  };
};

const getBlsByManufacturer = (manufacturers) => {
  manufacturers.items = manufacturers.items.filter((item) => {
    return item.shipping_bl.length !== 0;
  });
  manufacturers.items.map((item, index) => {
    item.billing = 'Sage';
    item.impact = 0;
    item.total = 0;
    let total_bls = 0;
    let orders_nbr = 0;
    let blNotSigned_nbr = 0;
    let orders = [];
    let bls = [];
    item.shipping_bl.forEach((item) => {
      const bl = JSON.parse(item.replace(/\//g, '\\/'));
      bls.push(bl);
      if (bl.total) {
        total_bls += parseFloat(bl.total);
      }
      if (bl.status !== 'signed') {
        blNotSigned_nbr++;
      }
      if (!orders.includes(bl.order_id)) {
        orders_nbr++;
        orders.push(bl.order_id);
      }
    });
    manufacturers.items[index].total = total_bls;
    manufacturers.items[index].shipping_bl = bls;
    manufacturers.items[index].impact = Math.ceil(blNotSigned_nbr / orders_nbr);
  });
  manufacturers.items.sort((a, b) => {
    if (a.impact < b.impact) {
      return -1;
    }
    if (a.impact > b.impact) {
      return 1;
    }
    return 0;
  });
  return manufacturers;
};
