//TODO: #User Interactions related# ACTION CREATORS
//TODO: #User Interface related# ACTION CREATORS
import {
  LOADING_DATA,
  SEARCH_RETAILER,
  OPEN_DELETE_MENU,
  CLOSE_DELETE_MENU,
  SEC_LOADING,
  SERVER_SIDE,
  LOADING_CHARTS_STATS,
  DISPATCHING_ORDERS,
  LOADING_ORDER_PRODUCTS,
  LOADING_DATA_COMPLETE,
} from './types';

export const loadingSec = (loading) => {
  return { type: SEC_LOADING, isLoading: loading };
};
/**Page: Creata an order */
export const searchRetailer = (searchText) => {
  return { type: SEARCH_RETAILER, searchText };
};

export const loadingData = (loading) => {
  return { type: LOADING_DATA, isLoading: loading };
};

export const loadingDataComplete = (loading) => {
  return { type: LOADING_DATA_COMPLETE, payload: loading };
};

export const loadingOrderProducts = (loading) => {
  return { type: LOADING_ORDER_PRODUCTS, isLoading: loading };
};

export const dispatchingOrders = (loading) => {
  return { type: DISPATCHING_ORDERS, isLoading: loading };
};

export const loadingStats = (loading) => {
  return { type: LOADING_CHARTS_STATS, isLoading: loading };
};

export const setServerSideOption = (state) => {
  return { type: SERVER_SIDE, serverSideOption: state };
};

export const openDeleteMenu = (anchor) => {
  return { type: OPEN_DELETE_MENU, anchor };
};

export const closeDeleteMenu = () => {
  return { type: CLOSE_DELETE_MENU };
};
