import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  WidgetId: {
    color: 'rgba(0,0,0,0.4)',
    fontWeight: 600,
  },
  WidgetTitle: {
    fontWeight: 'bold',
    color: '#314168',
  },
  WidgetOption: {
    fontWeight: 'bold',
    color: '#17c2e8d1',
    margin: theme.spacing(5),
    marginTop: -35,
  },
  widgetWrapper: {
    display: 'flex',
    minHeight: '100%',
  },
  widgetHeader: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    margin: theme.spacing(3),
    marginTop: theme.spacing(0),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  widgetRoot: {
    boxShadow: theme.customShadows.widget,
  },
  widgetBody: {
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  noPadding: {
    padding: 0,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden',
  },
  moreButton: {
    margin: -theme.spacing(1),
    padding: 0,
    width: 40,
    height: 40,
    color: theme.palette.text.hint,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'rgba(255, 255, 255, 0.35)',
    },
  },
}));
