import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    margin: '30px auto',
    width: '100%',
  },
  barStyle: {
    width: 'calc(100% - 10px)',
    height: 'auto',
    backgroundColor: '#FFFFFF',
    borderRadius: '7px',
    boxShadow: '2.2px 6px 10px rgba(0, 0, 0, 0.15)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0 0 20px',
    },
  },
  chartInfos: {
    marginBottom: 0,
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'row',
  },
  customerBarValue: {
    color: '#ADDEEC',
    fontSize: 37,
    margin: 30,
    fontWeight: '600',
  },
  gmvBarValue: {
    color: '#E0B20E',
    fontSize: 37,
    margin: 30,
    fontWeight: '600',
  },
  orderBarValue: {
    color: '#2E466E',
    fontSize: 37,
    margin: 30,
    fontWeight: '600',
  },
  barTitle: {
    color: '#919699',
    fontWeight: '400',
    whiteSpace: 'pre-line',
    fontSize: 17,
    width: '30%',
    overflow: 'hidden',
    marginTop: 35,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 170,
    marginTop: 30,
  },
  loadingCircle: {
    display: 'flex',
    justifyContent: 'center',
    margin: 27,
  },
}));
