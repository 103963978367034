import {
  GET_BLS,
  SELECT_BATCH_BL,
  UPDATE_SHIPPING_BILLS,
  GET_BLS_BY_ORDER,
} from '../actions/types';

const initialState = {
  bls: { items: [] },
  blsByOrder: { items: [] },
  batchBlSelected: {},
};

export const blsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BLS:
      return { ...state, bls: action.data };
    case SELECT_BATCH_BL:
      return { ...state, batchBlSelected: action.batchData };
    case UPDATE_SHIPPING_BILLS:
      return { ...state };
    case GET_BLS_BY_ORDER:
      return { ...state, blsByOrder: action.data };
  }
  return state;
};
