import React, { useState, useEffect } from 'react';

// packages
import axios from 'axios';

//styles
import useStyles from './styles';

// components
import CustomWidget from '../../components/CustomWidget';

// material ui components
import {
  Paper,
  Grid,
  Button,
  TextField,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  TableContainer,
  TablePagination,
  TableFooter,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Autocomplete } from '@material-ui/lab';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Modal from '@material-ui/core/Modal';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import EditIcon from '@material-ui/icons/Edit';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { getRetailers, selectRetailer, createRetailers } from '../../redux/actions/retailers';
import {
  changeItemsPerPage,
  changePage,
  getProductsByCategory,
  getCategories,
} from '../../redux/actions/products';
import Backdrop from '@material-ui/core/Backdrop/Backdrop';
import { addPrices } from '../../redux/actions/prices';
import { Category } from '@material-ui/icons';

export default function Pricing() {
  let classes = useStyles();
  const dispatch = useDispatch();

  // Global State
  const uiActionsState = useSelector((state) => state).uiActionsReducer;
  const retailersState = useSelector((state) => state).retailersReducer;
  const productsState = useSelector((state) => state).productsReducer;

  // Local State
  const [categoryClicked, setCategory] = useState({ id: '0', name: 'All' });
  const [modalOpen, setModalOpen] = useState(false);
  const [pointsNumber, setPointsNumber] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [addressEditable, setAddressEditable] = useState(false);
  const [pointsSnackbarOpen, setPointsSnackbarOpen] = useState(false);
  const [values, setValues] = useState({ firstname: '', lastname: '', phone: '', zone: '' });
  const [location, setLocation] = useState({
    region: '',
    street: '',
    city: '',
    postalCode: '',
    latitude: '',
    longitude: '',
  });
  const [controllers, setControllers] = React.useState({
    firstname: false,
    lastname: false,
    phone: false,
    buyPrice: false,
    sellPrice: false,
    postalCode: false,
  });
  const [coordinates, setCoordinates] = React.useState({ longitude: 0, latitude: 0 });
  const [prices, setPrices] = React.useState([
    {
      buyPrice: '',
      sellPrice: '',
      retailerID: '',
      sku: '',
    },
  ]);

  async function fetchData() {
    await dispatch(getRetailers());
    await dispatch(getCategories());
    await dispatch(getProductsByCategory('0', productsState.page, productsState.rowsPerPage));
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleRetailerChange = (objRetailer) => {
    dispatch(selectRetailer(objRetailer));
  };

  const handleChangePage = (event, newPage) => {
    dispatch(changePage(newPage));
    dispatch(getProductsByCategory(categoryClicked.id, newPage + 1, productsState.rowsPerPage));
  };

  const handleChangeRowsPerPage = (event) => {
    if (productsState.rowsPerPage !== parseInt(event.target.value, 10)) {
      dispatch(changeItemsPerPage(parseInt(event.target.value, 10)));
      dispatch(changePage(0));
      dispatch(getProductsByCategory(categoryClicked.id, 0, parseInt(event.target.value, 10)));
    }
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setAddressEditable(false);
    setModalOpen(false);
  };

  const handleChange = (prop) => (event) => {
    if (prop === 'phone' || prop === 'buyPrice' || prop === 'sellPrice' || prop === 'postalCode') {
      setControllers({ ...controllers, [prop]: isNaN(event.target.value) });
    } else if (prop === 'firstname' || prop === 'lastname') {
      setControllers({ ...controllers, [prop]: !isNaN(event.target.value[0]) });
    }

    if (prop === 'street' || prop === 'region' || prop === 'postalCode') {
      setLocation({ ...location, [prop]: event.target.value });
    } else {
      setValues({ ...values, [prop]: event.target.value });
    }
  };

  const verifyForm = () => {
    setControllers({
      ...controllers,
      phone: values.phone.length !== 8 || isNaN(values.phone),
      firstname: values.firstname.trim().length === 0,
      lastname: values.lastname.trim().length === 0,
      postalCode: location.postalCode.length !== 4 || isNaN(location.postalCode),
    });
    return (
      location.postalCode.length !== 4 ||
      isNaN(location.postalCode) ||
      values.phone.length !== 8 ||
      isNaN(values.phone) ||
      values.firstname.trim().length === 0 ||
      values.lastname.trim().length === 0
    );
  };

  const geoLocateRetailer = () => {
    const loc = window.navigator && window.navigator.geolocation;
    if (loc) {
      loc.getCurrentPosition(
        (position) => {
          setCoordinates({
            ...coordinates,
            longitude: position.coords.longitude,
            latitude: position.coords.latitude,
          });
          axios
            .get(
              `https://open.mapquestapi.com/geocoding/v1/reverse?key=so9jNZX67O1vY5nLR7WUA10ce0SPlca4&location=${position.coords.latitude},${position.coords.longitude}&includeRoadMetadata=true`,
            )
            .then(
              (res) => {
                const loc = res.data.results[0].locations[0];
                const latLng = res.data.results[0].providedLocation.latLng;
                setLocation({
                  ...location,
                  region: loc.adminArea3 ? loc.adminArea3 : 'region',
                  street: loc.street ? loc.street : 'street',
                  city: loc.adminArea6 ? loc.adminArea6 : 'city',
                  postalCode: loc.postalCode ? loc.postalCode : '0000',
                  latitude: latLng.lat,
                  longitude: latLng.lng,
                });
              },
              (error) => {},
            );
        },
        (error) => {},
      );
    }
  };

  const editAddress = () => {
    setAddressEditable(!addressEditable);
  };

  const handleCreateRetailer = () => {
    if (!verifyForm()) {
      dispatch(createRetailers(values, location));
    }
  };
  useEffect(() => {
    if (retailersState.retailerAdded.id) {
      setSnackbarOpen(true);
      setAddressEditable(false);
      setModalOpen(false);
    }
  }, [retailersState.retailers]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
    setPointsNumber(0);
    setPointsSnackbarOpen(false);
  };

  const handleCategorieClick = (category) => {
    setCategory({ ...categoryClicked, id: category.id, name: category.name });
    dispatch(changePage(0));
    dispatch(getProductsByCategory(category.id, productsState.page, productsState.rowsPerPage));
  };

  const handlePriceChange = (type, productSku) => (event) => {
    if (type === 'buy') {
      let price = prices.find((item) => item.sku === productSku);
      if (!price) {
        const point = {
          sellPrice: '',
          buyPrice: event.target.value,
          sku: productSku,
          retailerID: retailersState.selectedRetailer?.id,
        };
        setPrices((prices) => [...prices, point]);
      } else {
        price.buyPrice = event.target.value;
        let newPrices = prices.filter((item) => item.sku !== price.sku);
        newPrices.push(price);
        setPrices(newPrices);
      }
    } else {
      let price = prices.find((item) => item.sku === productSku);
      if (!price) {
        const point = {
          sellPrice: event.target.value,
          buyPrice: '',
          sku: productSku,
          retailerID: retailersState.selectedRetailer?.id,
        };
        setPrices((prices) => [...prices, point]);
      } else {
        price.sellPrice = event.target.value;
        let newPrices = prices.filter((item) => item.sku !== price.sku);
        newPrices.push(price);
        setPrices(newPrices);
      }
    }
  };

  const getPriceValue = (type, sku) => {
    let price = prices.find((item) => item.sku === sku);
    let point = '';
    if (price) {
      type === 'buy' ? (point = price.buyPrice) : (point = price.sellPrice);
    }
    return point;
  };

  const validateDataPoints = async () => {
    const priceNaN = prices.find((item) => isNaN(item.buyPrice) || isNaN(item.sellPrice));
    if (priceNaN) {
      alert('Prices must be numbers');
    } else if (!retailersState.selectedRetailer) {
      alert('please select a retailer');
    } else {
      await dispatch(
        addPrices(prices, retailersState.selectedRetailer.id, (points) => {
          setPointsNumber(points);
          setPointsSnackbarOpen(true);
        }),
      );
    }
  };

  const cancelDataPoints = () => {
    setPrices([{ buyPrice: '', sellPrice: '', retailerID: '', sku: '' }]);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Paper className={classes.containerPaper}>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert onClose={handleClose} severity="success">
              Retailer added successfully !
            </Alert>
          </Snackbar>
          <Snackbar
            open={pointsSnackbarOpen}
            autoHideDuration={5000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert onClose={handleClose} severity="success">
              {pointsNumber} Data points added successfully !
            </Alert>
          </Snackbar>
          {uiActionsState.isLoading ? (
            <CustomWidget title="Data Price Points">
              <div className={classes.loadingCircle}>
                <CircularProgress />
              </div>
            </CustomWidget>
          ) : (
            <CustomWidget title="Data Price Points">
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={modalOpen}
                onClose={handleModalClose}
                className={classes.orderDetails}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <div className={classes.paper}>
                  <Container component="main">
                    <CssBaseline />
                    <div className={classes.title}>
                      <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                      </Avatar>
                      <Typography component="h1" variant="h5">
                        Add New Retailer
                      </Typography>
                    </div>
                    <form className={classes.form} noValidate autoComplete="off">
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <FormControl error={controllers.firstname} fullWidth variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-amount">First name</InputLabel>
                            <OutlinedInput
                              name="firstName"
                              required
                              fullWidth
                              id="firstName"
                              label="First name"
                              onChange={handleChange('firstname')}
                              error={controllers.firstname}
                            />
                            {controllers.firstname ? (
                              <FormHelperText>First name can't start with digit</FormHelperText>
                            ) : null}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl error={controllers.lastname} fullWidth variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-amount">Last name</InputLabel>
                            <OutlinedInput
                              required
                              fullWidth
                              id="lastName"
                              label="Last name"
                              name="lastName"
                              autoComplete="lname"
                              onChange={handleChange('lastname')}
                              error={controllers.lastname}
                            />
                            {controllers.lastname ? (
                              <FormHelperText>Last name can't start with digit</FormHelperText>
                            ) : null}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl error={controllers.phone} fullWidth variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-amount">Phone</InputLabel>
                            <OutlinedInput
                              error={controllers.phone}
                              id="outlined-error-helper-text"
                              value={values.phone}
                              label="Phone"
                              autoComplete="phone"
                              onChange={handleChange('phone')}
                              startAdornment={
                                <InputAdornment position="start">+216 </InputAdornment>
                              }
                            />
                            {controllers.phone ? (
                              <FormHelperText>Phone number must be 8 digits</FormHelperText>
                            ) : null}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <Autocomplete
                            fullWidth
                            freeSolo
                            value={values.zone}
                            onChange={(e, value) => setValues({ ...values, zone: value })}
                            id="zone"
                            disableClearable
                            ListboxProps={{ style: { maxHeight: '177px' } }}
                            options={zones}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                label="Zone"
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                InputProps={{ ...params.InputProps, type: 'search' }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          {!addressEditable ? (
                            <FormControl fullWidth variant="outlined">
                              <InputLabel htmlFor="outlined-adornment-address">Address</InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-address"
                                required
                                fullWidth
                                onChange={handleChange('address')}
                                label="Address"
                                name="address"
                                value={
                                  location.region
                                    ? `${location.street}, ${location.region} ${location.postalCode}`
                                    : ''
                                }
                                endAdornment={
                                  <InputAdornment position="end">
                                    <Tooltip title="Edit address manually" aria-label="edit">
                                      <IconButton
                                        aria-label="locate-manually"
                                        onClick={editAddress}
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </Tooltip>
                                    <Divider
                                      orientation="vertical"
                                      className={classes.addressDivider}
                                    />
                                    <Tooltip title="Locate" aria-label="locate">
                                      <IconButton
                                        aria-label="geo locate address"
                                        onClick={geoLocateRetailer}
                                      >
                                        <MyLocationIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </InputAdornment>
                                }
                              />
                            </FormControl>
                          ) : (
                            <Grid container fullWidth spacing={1}>
                              <Grid item xs={12} sm={4}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel htmlFor="outlined-adornment-address">
                                    Street
                                  </InputLabel>
                                  <OutlinedInput
                                    id="outlined-adornment-Street"
                                    required
                                    onChange={handleChange('street')}
                                    label="Street"
                                    name="street"
                                    value={location.street ? location.street : ''}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel htmlFor="outlined-adornment-address">
                                    Region
                                  </InputLabel>
                                  <OutlinedInput
                                    id="outlined-adornment-region"
                                    required
                                    onChange={handleChange('region')}
                                    label="Region"
                                    name="region"
                                    value={location.region ? location.region : ''}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <FormControl
                                  error={controllers.postalCode}
                                  fullWidth
                                  variant="outlined"
                                >
                                  <InputLabel htmlFor="outlined-adornment-address">
                                    Postal Code
                                  </InputLabel>
                                  <OutlinedInput
                                    error={controllers.postalCode}
                                    id="outlined-adornment-Postal-Code"
                                    required
                                    onChange={handleChange('postalCode')}
                                    label="Postal Code"
                                    name="postalCode"
                                    value={location.postalCode ? location.postalCode : ''}
                                  />
                                  {controllers.postalCode ? (
                                    <FormHelperText>Postal Code must be 4 digits</FormHelperText>
                                  ) : null}
                                </FormControl>
                              </Grid>
                              <Hidden xsDown>
                                <Grid item sm={1}>
                                  <Tooltip title="Locate" aria-label="locate">
                                    <Fab>
                                      <IconButton
                                        aria-label="geo locate address"
                                        onClick={geoLocateRetailer}
                                      >
                                        <MyLocationIcon />
                                      </IconButton>
                                    </Fab>
                                  </Tooltip>
                                </Grid>
                              </Hidden>
                              <Hidden smUp>
                                <Grid item xs={12}>
                                  <Tooltip title="Locate" aria-label="locate">
                                    <Fab
                                      variant="extended"
                                      size="medium"
                                      style={{
                                        width: '100%',
                                        backgroundColor: '#edece8',
                                        marginTop: 5,
                                      }}
                                    >
                                      <IconButton
                                        aria-label="geo locate address"
                                        style={{ color: '#474747', fontSize: 17 }}
                                        onClick={geoLocateRetailer}
                                      >
                                        Localisation
                                        <MyLocationIcon style={{ marginLeft: 10 }} />
                                      </IconButton>
                                    </Fab>
                                  </Tooltip>
                                </Grid>
                              </Hidden>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleCreateRetailer}
                      >
                        Create
                      </Button>
                    </form>
                  </Container>
                </div>
              </Modal>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8} md={8} lg={6}>
                  <FormControl variant="outlined" style={{ width: '100%' }}>
                    <InputLabel id="demo-simple-select-outlined-label">
                      {categoryClicked.name}
                    </InputLabel>
                    <Select
                      label="Categories"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select-outlined-label"
                    >
                      {productsState.categories.map((cat) => (
                        <MenuItem
                          value={cat.id}
                          onClick={() => {
                            handleCategorieClick(cat);
                          }}
                        >
                          {cat.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={8} md={8} lg={6}>
                  <div className={classes.retailerContainer}>
                    <Autocomplete
                      style={{ width: '60%' }}
                      freeSolo
                      value={retailersState.selectedRetailer}
                      onChange={(e, v) => handleRetailerChange(v)}
                      id="retailer"
                      disableClearable
                      options={retailersState.retailers}
                      getOptionLabel={(option) =>
                        option.firstname.toString() + ' ' + option.lastname.toString()
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Shop name"
                          margin="normal"
                          variant="outlined"
                          color="secondary"
                          InputProps={{ ...params.InputProps, type: 'search' }}
                        />
                      )}
                    />
                    {/*<Button
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.addButton}
                      startIcon={<PersonAddIcon/>}
                      onClick={handleModalOpen}
                    >
                      Add Retailer
                    </Button>*/}
                  </div>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Typography className={classes.categoryName}>
                    {categoryClicked.name}{' '}
                    {categoryClicked.name === 'All' ? 'Categories' : 'Category'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table aria-label="spanning table">
                      <TableBody>
                        {productsState.productsByCategory &&
                          productsState.productsByCategory.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell style={{ fontSize: 17 }}>{row.name}</TableCell>
                              <TableCell align="left">
                                <Chip
                                  label={
                                    'x' +
                                    row.custom_attributes.find(
                                      (item) => item.attribute_code === 'qty_pcb',
                                    ).value
                                  }
                                  className={classes.productQty}
                                />
                              </TableCell>
                              <TableCell align="right" style={{ minWidth: 170 }}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel htmlFor="outlined-adornment-price">
                                    Buying Price
                                  </InputLabel>
                                  <OutlinedInput
                                    className={classes.priceField}
                                    id={`${row.id}-buy`}
                                    label="BUYING PRICE"
                                    onChange={handlePriceChange('buy', row.sku)}
                                    value={getPriceValue('buy', row.sku) || ''}
                                  />
                                </FormControl>
                              </TableCell>
                              <TableCell align="right" style={{ minWidth: 170 }}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel htmlFor="outlined-adornment-price">
                                    Selling Price
                                  </InputLabel>
                                  <OutlinedInput
                                    className={classes.priceField}
                                    id={`${row.id}-sell`}
                                    label="SELLING PRICE"
                                    onChange={handlePriceChange('sell', row.sku)}
                                    value={getPriceValue('sell', row.sku) || ''}
                                  />
                                </FormControl>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            colSpan={5}
                            count={productsState.total}
                            page={productsState.page}
                            rowsPerPage={productsState.rowsPerPage}
                            SelectProps={{
                              inputProps: { 'aria-label': '' },
                              native: true,
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.formButtons}>
                    <Button
                      type="reset"
                      variant="outlined"
                      className={classes.cancelButton}
                      onClick={cancelDataPoints}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      className={classes.validateButton}
                      onClick={validateDataPoints}
                    >
                      Validate
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </CustomWidget>
          )}
        </Paper>
      </Grid>
    </>
  );
}

// list of zone
const zones = [
  'Banlieue Nord (Marsa /Carthage /Gammarth /Kram/La goulette)',
  'L’Aouina / Lac / Soukra',
  'Ariana Jadida / Cité El Milaha',
  'Borj Louzir / El Ghazela / Rawed',
  'Menzah / Manar / Ennasr',
  'Cité olympique / Cité El Khadhra / Mutuelleville',
  'Centre-ville / Medina',
  'Bardo / Ras Tabia / Jbal Lahmar / El Omrane',
  'Mellassine / Cité Ezzouhour',
  'El Omrane supérieur / Ibn Khaldoun / Intilaka / Rafaha / Tahrir',
  'Manouba / Cité Tadhamen / Dawar Hicher / Oued Ellil / Jdaida / Tebourba',
  'El Denden / El Agba / El Zahrouni / Sidi Hassine',
  'Fouchana / El Mhamdia / El Mourouj / Naassen',
  '13 Août / Cité Mohamed Ali / El Kabaria / Ibn Sina / Jbel Jelloud / Fathallah',
  'Ben Arous / Medina Jadida / El Yasminet / Megrine',
  'Banlieue Sud (Rades / Ezzahra / Boumhel / Hammam-lif / Hamman Chat / Borj Cedria )',
];
