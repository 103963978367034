//TODO:  #User Interface related# REDUCER
import {
  SEARCH_RETAILER,
  LOADING_DATA,
  OPEN_DELETE_MENU,
  CLOSE_DELETE_MENU,
  SEC_LOADING,
  SERVER_SIDE,
  LOADING_CHARTS_STATS,
  DISPATCHING_ORDERS,
  LOADING_ORDER_PRODUCTS,
  LOADING_DATA_COMPLETE,
} from '../actions/types';

const initialState = {
  searchRetailerText: '',
  isLoading: false,
  isLoadingComplete: false,
  orderProductsLoader: false,
  total: 0,
  anchorEl: null,
  isSecLoading: false,
  serverSideOption: false,
  dispatchingLoader: false,
  chartsLoader: true,
};

export const uiActionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_RETAILER:
      return {
        ...state,
        searchRetailerText: action.searchText,
        isLoadingComplete: state.isLoadingComplete,
      };
    case LOADING_DATA:
      return { isLoading: action.isLoading, isLoadingComplete: state.isLoadingComplete };
    case LOADING_DATA_COMPLETE:
      return { isLoading: state.isLoading, isLoadingComplete: action.payload };
    case LOADING_ORDER_PRODUCTS:
      return { orderProductsLoader: action.isLoading, isLoadingComplete: state.isLoadingComplete };
    case DISPATCHING_ORDERS:
      return { dispatchingLoader: action.isLoading, isLoadingComplete: state.isLoadingComplete };
    case SERVER_SIDE:
      return {
        serverSideOption: action.serverSideOption,
        isLoadingComplete: state.isLoadingComplete,
      };
    case OPEN_DELETE_MENU:
      return { ...state, anchorEl: action.anchor };
    case CLOSE_DELETE_MENU:
      return { ...state, anchorEl: null };
    case SEC_LOADING:
      return { isSecLoading: action.isLoading, isLoadingComplete: state.isLoadingComplete };
    case LOADING_CHARTS_STATS:
      return { chartsLoader: action.isLoading, isLoadingComplete: state.isLoadingComplete };
    default:
      return state;
  }
};
