import {
  GET_PRODUCTS,
  CHANGE_PRODUCTS_PAGE,
  SET_ITEMS_PER_PAGE,
  SEARCH_PRODUCT,
  LOADING_PRODUCTS,
  GET_PRODUCTS_BY_CATEGORY,
  GET_CATEGORIES,
  GET_PRODUCTS_TO_SHIP,
  GET_ORDER_PRODUCTS,
  CLEAR_ORDER_PRODUCTS,
} from './types';
import axios from 'axios';
import _ from 'lodash';
import { loadingData, loadingOrderProducts } from './uiActions';
import { updateOrder } from './orders';
import { pushRetailerNotification } from './orders';
import oosImage from '../../images/oos-image.png';
import { store } from '../stores';
import { getLocalStorage } from '../../hooks/useLocalStorage';
import { handleApiErrors } from '../../utils/handleApiErrors';

const baseUrl = process.env.REACT_APP_BASE_URL;

const publicBaseUrl = process.env.REACT_APP_PUBLIC_BASE_URL;

const token = getLocalStorage('id_token');

export const getProducts = (searchCriteria, callback, currentPage, pageSize) => {
  {
    return (dispatch) => {
      axios
        .get(
          baseUrl +
            `products?searchCriteria[filterGroups][0][filters][0][field]=status&searchCriteria[filterGroups][0][filters][0][value]=1&searchCriteria[filterGroups][0][filters][0][conditionType]=eq&searchCriteria[filterGroups][1][filters][1][field]=name&searchCriteria[filterGroups][1][filters][1][value]=%25${searchCriteria}%25& searchCriteria[filterGroups][1][filters][1][conditionType]=like&searchCriteria[filterGroups][1][filters][2][field]=sku&searchCriteria[filterGroups][1][filters][2][value]=%25${searchCriteria}%25& searchCriteria[filterGroups][1][filters][2][conditionType]=like&searchCriteria[pageSize]=${pageSize}&searchCriteria[currentPage]=${
              currentPage + 1
            }`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          },
        )
        .then((response) => {
          let products = response.data.items.map((val) => {
            const qte = val.custom_attributes.filter((attr) => attr.attribute_code === 'qty_pcb')[0]
              .value;
            const pu = val.custom_attributes.filter(
              (attr) => attr.attribute_code === 'unit_price',
            )[0].value;
            val['qt'] = val.extension_attributes.stock_item //qte in stock
              ? val.extension_attributes.stock_item.qty
              : 0;
            val['qtu'] = Number(qte);
            val['pu'] = Number(pu);
            return val;
          });
          let total = response.data.total_count;
          dispatch({ type: GET_PRODUCTS, products, total });
          callback();
        })
        .catch((error) => handleApiErrors(error));
    };
  }
};

export const changePage = (page) => {
  return { type: CHANGE_PRODUCTS_PAGE, page };
};

export const changeItemsPerPage = (itemsNb) => {
  return { type: SET_ITEMS_PER_PAGE, itemsNb };
};

export const searchProducts = (searchCriteria) => {
  return { type: SEARCH_PRODUCT, searchCriteria };
};

export const manageLoader = (isLoading) => {
  return { type: LOADING_PRODUCTS, isLoading };
};

export const getProductsByCategory = (categoryID, currentPage, pageSize) => {
  return (dispatch) => {
    dispatch(loadingData(true));
    let searchCriteria = '';
    categoryID === '0'
      ? (searchCriteria = `searchCriteria[pageSize]=${pageSize}&searchCriteria[currentPage]=${currentPage}`)
      : (searchCriteria = `searchCriteria[filterGroups][0][filters][0][field]=category_id&searchCriteria[filterGroups][0][filters][0][value]=${categoryID}&searchCriteria[filterGroups][0][filters][0][conditionType]=eq&searchCriteria[pageSize]=${pageSize}&searchCriteria[currentPage]=${currentPage}`);
    axios
      .get(baseUrl + `products?${searchCriteria}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let products = response.data.items;
        const total = response.data.total_count;
        dispatch({ type: GET_PRODUCTS_BY_CATEGORY, products, total });
        dispatch(loadingData(false));
      })
      .catch((error) => handleApiErrors(error));
  };
};

export const getCategories = () => {
  return (dispatch) => {
    dispatch(loadingData(true));
    axios
      .get(baseUrl + `categories/list?searchCriteria=`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        const categories = response.data.items;
        const dataFiltered = [];
        categories.forEach((category) => {
          if (category.name !== 'Root Catalog' && category.name !== 'Default Category') {
            category.name = category.name.trim();
            dataFiltered.push(category);
          }
        });
        dataFiltered.push({ id: '0', name: 'All' });
        const dataOrdered = _.orderBy(dataFiltered, [(item) => item.name.toLowerCase()]);
        const data = _.uniqBy(dataOrdered, 'name');
        dispatch({ type: GET_CATEGORIES, data });
        dispatch(loadingData(false));
      })
      .catch((error) => handleApiErrors(error));
  };
};

export const getOrderProducts = (orderId) => {
  return (dispatch) => {
    dispatch(loadingOrderProducts(true));
    axios
      .get(baseUrl + `products/order?orderId=${orderId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        const products = response.data;
        dispatch({ type: GET_ORDER_PRODUCTS, products });
        dispatch(loadingOrderProducts(false));
      })
      .catch((error) => handleApiErrors(error));
  };
};

export const clearOrderProducts = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_ORDER_PRODUCTS });
  };
};

export const commentOrderAndNotifyUser = (order) => {
  store.dispatch(updateOrder(true));
  const title = 'منتوجات مقطوعة';
  const message =
    'حريفنا العزيز حبينا نعتذرولك الي الكماندة متاعك فيها منتوجات موش باش توصلك باش تشوفهم ادخل ثبت في الكماندة متاعك';
  const image = publicBaseUrl + oosImage;
  const body = [`{action: update order delivery date}`, `{ order_id: ${order.entity_id} }`];
  pushRetailerNotification(order.customer_id, message, body, title, image);
};

export const updateProductsToShip = (order, shippedProducts) => {
  const newOrderItems = [];
  order.items.forEach((item) => {
    let product = shippedProducts.find((product) => product.sku == item.sku);
    if (product) {
      const obj = {
        item_id: item.item_id,
        weight: Number(product.value),
      };
      newOrderItems.push(obj);
    }
  });
  const data = {
    entity: {
      entity_id: order.entity_id,
      state: order.state,
      status: order.status,
      items: newOrderItems,
    },
  };
  return (dispatch) => {
    const option = {
      method: 'put',
      url: baseUrl + `orders/create`,
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
      data: data,
    };
    axios(option)
      .then((response) => {
        const products = response.data;
        dispatch({ type: GET_PRODUCTS_TO_SHIP, products });
        commentOrderAndNotifyUser(order);
        dispatch(loadingData(false));
        const title = 'منتوجات مقطوعة';
        const message =
          'حريفنا العزيز حبينا نعتذرولك الي الكماندة متاعك فيها منتوجات موش باش توصلك باش تشوفهم ادخل ثبت في الكماندة متاعك';
        const image = publicBaseUrl + oosImage;
        const body = [`{action: update order delivery date}`, `{ order_id: ${order.entity_id} }`];
        pushRetailerNotification(order.customer_id, message, body, title, image);
      })
      .catch((error) => handleApiErrors(error));
  };
};
