import React, { useEffect } from 'react'; //hooks logic
import {
  Paper,
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  ButtonGroup,
  Chip,
  InputBase,
  Tooltip,
  Fab,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import { Search as SearchIcon, Add, Delete } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';

//routing
import { useHistory } from 'react-router-dom';

//styles
import useStyles from './styles';
import classnames from 'classnames';
// components
import CustomWidget from '../../components/CustomWidget';

//Redux logic
import { useSelector, useDispatch } from 'react-redux';
import { selectRetailer, getRetailers, clearRetailer } from '../../redux/actions/retailers';
import {
  selectDeliveryDate,
  selectProduct,
  editSelectedProduct,
  createOrder,
  clearFrom,
  deleteProduct,
} from '../../redux/actions/orders';
import {
  getProducts,
  changePage,
  changeItemsPerPage,
  searchProducts,
  manageLoader,
} from '../../redux/actions/products';

function ccyFormat(num) {
  return `${num?.toFixed(2)}`;
}
export default function Orders() {
  let classes = useStyles();
  const retailersState = useSelector((state) => state).retailersReducer;
  const ordersState = useSelector((state) => state).ordersReducer;
  const productsState = useSelector((state) => state).productsReducer;
  const uiActionsState = useSelector((state) => state).uiActionsReducer;
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(getRetailers());
    dispatch(
      getProducts(
        productsState.searchCriteria,
        () => dispatch(manageLoader(false)),
        productsState.page,
        productsState.rowsPerPage,
      ),
    );
  }, []);
  /**Handle Change */
  const handleRetailerChange = (objRetailer) => {
    dispatch(selectRetailer(objRetailer));
  };
  const handleDeliveryDateChange = (day) => {
    dispatch(selectDeliveryDate(day));
  };
  const handleSearchChange = (txt) => {
    dispatch(searchProducts(txt));
  };
  const handleChangePage = (event, newPage) => {
    dispatch(manageLoader(true));
    dispatch(
      getProducts(
        productsState.searchCriteria,
        () => {
          dispatch(manageLoader(false));
          dispatch(changePage(newPage));
        },
        newPage,
        productsState.rowsPerPage,
      ),
    );
  };
  const handleChangeRowsPerPage = (event) => {
    if (productsState.rowsPerPage !== parseInt(event.target.value, 10)) {
      dispatch(manageLoader(true));
      dispatch(changeItemsPerPage(parseInt(event.target.value, 10)));
      dispatch(changePage(0));
      dispatch(
        getProducts(
          productsState.searchCriteria,
          () => {
            dispatch(manageLoader(false));
          },
          0,
          parseInt(event.target.value, 10),
        ),
      );
    }
  };
  const handleQteChange = (lstProducts, lstRows, id, qte) => {
    //***Change all selected rows qte */
    let qteChanged = Number(qte);
    let prd = lstProducts.find((item) => item.id === id);
    if (Number.isNaN(qteChanged)) {
      alert('Please Enter valid quantity');
    } else {
      if (prd.qt >= qteChanged) {
        dispatch(editSelectedProduct(lstProducts, lstRows, id, qteChanged));
      } else {
        alert('Quantity not available in Stock');
      }
    }
  };
  const addProductToOrder = (lstAll, lstSelected, rowsSelected, id) => {
    if (lstSelected.length === 0) {
      dispatch(selectProduct(lstAll, id));
    } else {
      let duplicated = rowsSelected.find((elm) => elm.id === id);
      if (duplicated) {
        dispatch(editSelectedProduct(lstSelected, rowsSelected, id, duplicated.qeCmd + 1));
      } else {
        dispatch(selectProduct(lstAll, id));
      }
    }
  };
  const submitNewOrder = (retailer, deliveryDate, total, selectedProducts) => {
    if (!retailer.addresses[0]) {
      alert("Retailer selected doesn't have an address");
    } else if (retailer && selectedProducts.length > 0) {
      //Check valid qte for each selected product
      let selectedNullQte = selectedProducts.filter((item) => item.cmdQte === 0);
      if (selectedNullQte.length === 0) {
        dispatch(
          createOrder(
            retailer,
            deliveryDate,
            total,
            selectedProducts,
            () => dispatch(clearFrom()),
            () => {
              dispatch(clearRetailer());
              history.push(`/app/dashboard`);
            },
          ),
        );
      } else {
        alert('All ordered products quantity must be greater then 0');
      }
    } else {
      alert('Please Complete all needed informations for your order');
    }
  };
  const handleCancel = () => {
    dispatch(clearFrom());
    dispatch(clearRetailer());
    history.push(`/app/dashboard`);
  };
  return (
    <>
      <Grid container spacing={2}>
        {/**Products List */}
        <Grid item xs={12} md={7}>
          <Paper>
            <CustomWidget title="Stock situation" disableWidgetMenu>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon className={classes.searchIc} />
                </div>
                <InputBase
                  placeholder="Search for a specific SKU..."
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  defaultValue={productsState.searchCriteria}
                  onChange={(e) => {
                    handleSearchChange(e.target.value);
                    if (e.target.value.length >= 3 || e.target.value.length === 0) {
                      dispatch(manageLoader(true));
                      dispatch(
                        getProducts(
                          e.target.value,
                          () => {
                            dispatch(manageLoader(false));
                          },
                          0,
                          productsState.rowsPerPage,
                        ),
                      );
                    }
                  }}
                />
              </div>
              {productsState.isLoading ? (
                <div className={classes.loaderContainer}>
                  <CircularProgress size={26} />
                </div>
              ) : (
                <>
                  {productsState.products.length > 0 ? (
                    <>
                      <TableContainer>
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              {columns.map((column) => (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{
                                    minWidth: column.minWidth,
                                    backgroundColor: 'transparent',
                                    color: 'rgba(0,0,0,0.42)',
                                    borderBottom: '2px solid #d7d7d7',
                                  }}
                                >
                                  {column.label}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {productsState.products.map((row) => {
                              let currentId = row.id;
                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={currentId}
                                  onClick={() => {
                                    addProductToOrder(
                                      productsState.products,
                                      ordersState.selectedProducts,
                                      ordersState.selectedRows,
                                      currentId,
                                    );
                                  }}
                                >
                                  {columns.map((column) => {
                                    const value = row[column.id];
                                    return column.id !== 'plus' ? (
                                      <TableCell key={column.id} align={column.align} size="small">
                                        {typeof value === 'number' ? (
                                          <Typography
                                            color="primary"
                                            style={{ fontWeight: 'bold' }}
                                          >
                                            {value}
                                          </Typography>
                                        ) : (
                                          <Typography>{value}</Typography>
                                        )}
                                      </TableCell>
                                    ) : (
                                      <TableCell key={column.id} align={column.align} size="small">
                                        <Tooltip
                                          title="Add a product"
                                          aria-label="Add a product"
                                          onClick={() => {}}
                                        >
                                          <Fab color="primary" size="small">
                                            <Add fontSize="small" />
                                          </Fab>
                                        </Tooltip>
                                      </TableCell>
                                    );
                                  })}
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={productsState.total}
                        page={productsState.page}
                        rowsPerPage={productsState.rowsPerPage}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                    </>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      Sorry, there is no matching products to display
                    </div>
                  )}
                </>
              )}
            </CustomWidget>
          </Paper>
        </Grid>
        {/**New Order Form */}
        <Grid item xs={12} md={5}>
          <CustomWidget title="Create a new order" id="">
            <div>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Autocomplete
                    freeSolo
                    value={retailersState.selectedRetailer}
                    onChange={(e, v) => handleRetailerChange(v)}
                    id="retailer"
                    disableClearable
                    options={retailersState.retailers}
                    getOptionLabel={(option) =>
                      option.firstname.toString() + ' ' + option.lastname.toString()
                    }
                    /*options={retailersState.retailers.map(
                      option =>
                        option.firstname.toString() +
                        " " +
                        option.lastname.toString(),
                    )}*/
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Shop name"
                        margin="normal"
                        variant="outlined"
                        color="secondary"
                        InputProps={{ ...params.InputProps, type: 'search' }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={7}>
                  <TextField
                    onChange={(e) => handleDeliveryDateChange(e.target.value)}
                    autoComplete=""
                    name="dileverydate"
                    variant="outlined"
                    type="date"
                    value={ordersState.selectedDate}
                    required
                    fullWidth
                    id="dileverydate"
                    label="Delivery date"
                    autoFocus
                    color="secondary"
                    InputProps={{
                      inputProps: { min: moment().format('YYYY-MM-DD') },
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                {/**Orders list */}
                {ordersState.selectedProducts.length > 0 && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="h6" className={classes.skusTitle}>
                        SKUs to order
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TableContainer>
                        <Table className={classes.oTable} aria-label="spanning table">
                          <TableBody>
                            {ordersState.selectedRows.map((row) => (
                              <>
                                <TableRow key={row.id} className={classes.selectedProductRow}>
                                  <TableCell
                                    size="small"
                                    className={classnames(
                                      classes.borderlessCell,
                                      classes.badgeCell,
                                    )}
                                    style={{ width: '20%' }}
                                  >
                                    {row.name}
                                  </TableCell>
                                  <TableCell
                                    className={classnames(
                                      classes.borderlessCell,
                                      classes.badgeCell,
                                    )}
                                    align="left"
                                    size="small"
                                    style={{ width: '7%' }}
                                  >
                                    <Chip
                                      size="small"
                                      label={'x' + row.qtu}
                                      className={classes.tableQteBadge}
                                    />
                                  </TableCell>
                                  <TableCell
                                    className={classnames(
                                      classes.borderlessCell,
                                      classes.badgeCell,
                                    )}
                                    align="left"
                                    size="small"
                                    style={{ width: '7%' }}
                                  >
                                    <Chip size="small" label={row.prixu} color="primary" />
                                  </TableCell>
                                  <TableCell
                                    className={classnames(
                                      classes.borderlessCell,
                                      classes.badgeCell,
                                    )}
                                    size="small"
                                    align="left"
                                    style={{ width: '4%' }}
                                  >
                                    <IconButton
                                      color="primary"
                                      aria-label="delete product from order"
                                      component="span"
                                      style={{
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                      }}
                                      onClick={() =>
                                        dispatch(
                                          deleteProduct(
                                            row.id,
                                            ordersState.selectedProducts,
                                            ordersState.selectedRows,
                                            ordersState.total,
                                          ),
                                        )
                                      }
                                    >
                                      <Delete />
                                    </IconButton>
                                  </TableCell>
                                  <TableCell
                                    className={classnames(
                                      classes.borderlessCell,
                                      classes.badgeCell,
                                    )}
                                    align="left"
                                    style={{ width: '7%' }}
                                  >
                                    <TextField
                                      id="price"
                                      label="QTE"
                                      variant="outlined"
                                      value={row.qeCmd}
                                      onChange={(e) => {
                                        handleQteChange(
                                          ordersState.selectedProducts,
                                          ordersState.selectedRows,
                                          row.id,
                                          e.target.value,
                                          ordersState.total,
                                        );
                                      }}
                                      focused
                                      fullWidth
                                      inputProps={{
                                        min: 0,
                                        className: classes.inputPriceTable,
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              </>
                            ))}

                            <TableRow>
                              <TableCell
                                className={classnames(classes.borderedCell, classes.badgeCell)}
                              >
                                <Typography variant="h6" className={classes.total}>
                                  Total
                                </Typography>
                              </TableCell>
                              <TableCell
                                className={classnames(classes.borderedCell, classes.badgeCell)}
                              />
                              <TableCell
                                className={classnames(classes.borderedCell, classes.badgeCell)}
                              />
                              <TableCell
                                className={classnames(classes.borderedCell, classes.badgeCell)}
                              />
                              <TableCell
                                align="right"
                                className={classnames(classes.borderedCell, classes.badgeCell)}
                              >
                                <Typography
                                  variant="h6"
                                  color="primary"
                                  className={classes.totalValue}
                                >
                                  {ccyFormat(ordersState.total) + ' TND'}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid container direction="row" justify="flex-end" alignItems="center">
                {uiActionsState.isLoading ? (
                  <CircularProgress className={classes.loadingCreation} />
                ) : (
                  <ButtonGroup orientation="horizontal" className={classes.btnGroup}>
                    <Button
                      variant="outlined"
                      className={classes.button}
                      fullWidth
                      onClick={handleCancel}
                    >
                      CANCEL
                    </Button>
                    <Button
                      className={classes.btnPrimary}
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        submitNewOrder(
                          retailersState.selectedRetailer,
                          ordersState.selectedDate,
                          ordersState.total,
                          ordersState.selectedProducts,
                        )
                      }
                    >
                      CREATE
                    </Button>
                  </ButtonGroup>
                )}
              </Grid>
            </div>
          </CustomWidget>
        </Grid>
      </Grid>
    </>
  );
}

/**
 * Products Table
 */
const columns = [
  { id: 'name', label: 'SKU Name', minWidth: 100 },
  {
    id: 'qt',
    label: 'Qt\u00a0In\u00a0Stock\u00a0(PCB)',
    minWidth: 5,
    align: 'center',
  },
  {
    id: 'price',
    label: 'Price/PCB\u00a0(TND)',
    minWidth: 5,
    align: 'center',
  },
  {
    id: 'pu',
    label: 'Price/Unit\u00a0(PCB)',
    minWidth: 5,
    align: 'center',
  },
  {
    id: 'plus',
    label: '',
    minWidth: 5,
    align: 'center',
  },
];
