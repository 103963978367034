import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import classnames from 'classnames';

// styles
import useStyles from './styles';

// components
import Header from '../Header';
import Sidebar from '../Sidebar';

// pages
import Dashboard from '../../pages/dashboard';
import Orders from '../../pages/orders';
import Dispatch from '../../pages/dispatch';
import Bls from '../../pages/bls/Bls';
import Pricing from '../../pages/pricing/Pricing';
import Charts from '../../pages/charts/Charts';
import ListingBls from '../../pages/listingBls/ListingBls';
import Confirmation from '../../pages/confirmation/Confirmation';
import PriceDashboard from '../../pages/pricedashboard/PriceDashboard';
// context
import { useLayoutState } from '../../context/LayoutContext';

function Layout(props) {
  let classes = useStyles();

  // global
  let layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route path="/app/price" component={PriceDashboard} />
            {/*<Route path="/app/bonslivraison:order" component={ListingBls} />*/}
            <Route path="/app/dashboard" component={Dashboard} />
            <Route path="/app/charts" component={Charts} />
            <Route path="/app/dashboard:new" component={Orders} />
            <Route path="/app/dispatch" component={Dispatch} />
            {/*<Route path="/app/bls" component={Bls} />*/}
            <Route path="/app/pricing" component={Pricing} />
            <Route path="/app/confirm-signature/:doc_id" component={Confirmation} />
          </Switch>
        </div>
      </>
    </div>
  );
}
export default withRouter(Layout);
