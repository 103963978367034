import { GET_ORDERS_STATS, GET_GMVS_STATS, GET_CUSTOMERS_STATS } from '../actions/types';

const initialState = {
  orders: false,
  gmvs: false,
  customers: false,
  total_orders: false,
  total_gmvs: false,
  total_customers: false,
};

export const chartsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDERS_STATS:
      return { ...state, orders: action.data, total_orders: action.data.total };
    case GET_GMVS_STATS:
      return { ...state, gmvs: action.data, total_gmvs: action.data.total };
    case GET_CUSTOMERS_STATS:
      return { ...state, customers: action.data, total_customers: action.data.total };
  }
  return state;
};
