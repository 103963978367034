import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  comboTitle: {
    backgroundColor: 'white',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  box: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignSelf: 'flew-end',
    top: 25,
    bottom: 20,
    left: 'auto',
    right: 'auto',
    marginTop: 10,
    marginBottom: 15,
    '& > *': {
      margin: theme.spacing(2),
    },
  },
  btn_filter: {
    borderRadius: 50,
    fontSize: 13,
    textTransform: 'normal',
    //marginTop: 10,
    marginBottom: 27,
  },
  doneIcon: {
    height: 20,
    width: 20,
    marginTop: 0,
    marginBottom: -5,
    marginLeft: 10,
  },
  title: {
    fontWeight: 'bold',
    color: '#314168',
  },
  dataPointsValue: {
    fontWeight: 'bold',
    padding: theme.spacing(4),
  },
  categorieItem: {
    height: theme.spacing(5),
  },
}));
