import {
  CHANGE_ORDERS_PAGE,
  CHANGE_ORDERS_PER_PAGE,
  CLEAR_FORM,
  CREATE_ORDER,
  DELETE_PRODUCT,
  EDIT_SELECTED_PRODUCT,
  GET_ALL_BLS,
  GET_BLS_FOR_ORDER,
  GET_ORDER_BY_ID,
  IS_CHANGING_ORDERS_STATUS,
  REMOVE_ORDER_IN_LIST,
  SELECT_DELIVERY_DATE,
  SELECT_PICKLIST_DATE,
  SELECT_PRODUCT,
  SWITCH_FILTER_ORDERS,
  UPDATE_ORDERS_DETAILS,
  UPDATE_ORDERS_STATUS,
  UPDATING_ORDER,
  UPDATE_TOTAL,
  GET_AGENT_REPORT,
  GET_SALES_LIST,
  LOADING_REPORT,
  GET_ORDERS_BY_DATE,
  SELECT_ORDER_ON_MAP,
} from '../types';
import axios from 'axios';
import _ from 'lodash';
import { store } from '../../stores';
import moment from 'moment';
import { loadingData, dispatchingOrders } from '../uiActions';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { clearRetailer } from '../retailers';
import deliveryDateImg from '../../../images/delivery-date-image.png';
import { getLocalStorage } from '../../../hooks/useLocalStorage';
import { handleApiErrors } from '../../../utils/handleApiErrors';
import { formatOrderFailures } from './formatOrderFailures';
import { getOrders } from './getOrders';

const baseUrl = process.env.REACT_APP_BASE_URL;

const publicBaseUrl = process.env.REACT_APP_PUBLIC_BASE_URL;

export { getOrders };
export const loadingReport = (isLoading) => {
  return { type: LOADING_REPORT, isLoading };
};
export const getAgentReport = (startDate, endDate, agentId, callback) => {
  const token = getLocalStorage('id_token');

  return (dispatch) => {
    dispatch(loadingReport(true));
    const start = moment(startDate).format('DD/MM/YYYY');
    const end = moment(endDate).format('DD/MM/YYYY');
    axios({
      method: 'get',
      url: agentId
        ? baseUrl +
          `agents/agent_report?deliveryDateStart=${start}&deliveryDateEnd=${end}&agentId=${agentId}`
        : baseUrl + `agents/agent_report?deliveryDateStart=${start}&deliveryDateEnd=${end}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        let data = response.data;

        dispatch(loadingReport(false));
        dispatch({ type: GET_AGENT_REPORT, report: data });
        callback(data);
      })
      .catch((error) => handleApiErrors(error));
  };
};
export const getSalesList = (startDate, endDate, manufacturerId, callback) => {
  const token = getLocalStorage('id_token');

  return (dispatch) => {
    dispatch(loadingReport(true));
    const start = moment(startDate).format('DD/MM/YYYY');
    const end = moment(endDate).format('DD/MM/YYYY');
    axios({
      method: 'get',
      url: manufacturerId
        ? baseUrl +
          `orders/saleslist_without_sum?deliveryDateStart=${start}&deliveryDateEnd=${end}&manufacturer=${manufacturerId}`
        : baseUrl +
          `orders/saleslist_without_sum?deliveryDateStart=${start}&deliveryDateEnd=${end}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        let data = response.data;
        dispatch(loadingReport(false));
        dispatch({ type: GET_SALES_LIST, list: data });
        callback(data);
      })
      .catch((error) => handleApiErrors(error));
  };
};

export const editingOrder = (editOrder) => {
  return { type: UPDATING_ORDER, editOrder };
};

export const passOrderData = (order, deliveryDate) => {
  return (dispatch) => {
    dispatch(clearRetailer());
    dispatch(clearFrom());
    dispatch(selectDeliveryDate(deliveryDate));
  };
};

export const printOrderSummaries = (orders) => {
  const token = getLocalStorage('id_token');

  const entityIds = orders.map((item) => {
    return item.entity_id;
  });
  const orders_ids = entityIds.toString();
  var data = JSON.stringify({
    ordersIds: orders_ids,
  });
  axios({
    method: 'post',
    url: baseUrl + 'order_summary/bulk_zip_generate',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: data,
  })
    .then((response) => {
      let properties =
        'height=' +
        window.innerHeight +
        ',width=' +
        window.innerWidth +
        ',' +
        'scrollbars=yes,status=yes';
      window.open(response.data, properties);
    })
    .catch((error) => handleApiErrors(error));
};

export const generateOrderSummary = (orderID) => {
  const token = getLocalStorage('id_token');

  var data = JSON.stringify({
    orderId: orderID,
  });
  axios({
    method: 'post',
    url: baseUrl + 'order_summary/generate',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: data,
  })
    .then((response) => {
      let properties =
        'height=' +
        window.innerHeight +
        ',width=' +
        window.innerWidth +
        ',' +
        'scrollbars=yes,status=yes';
      window.open(response.data, properties);
    })
    .catch((error) => handleApiErrors(error));
};

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';
const exportToCSV = (csvData, fileName) => {
  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};
export const generatePickList = (orders) => {
  const token = getLocalStorage('id_token');

  return (dispatch) => {
    const entityIds = orders.map((item) => {
      return item.entity_id;
    });
    const orders_ids = entityIds.toString();
    axios
      .get(baseUrl + `orders/picklist?ordersIds=${orders_ids}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        window.open(response.data);
      })
      .catch((error) => handleApiErrors(error));
  };
};

export const generateSalesList = (deliveryDate) => {
  const token = getLocalStorage('id_token');

  const valideDate = moment(deliveryDate).format('DD/MM/YYYY');
  return (dispatch) => {
    axios
      .get(baseUrl + `orders/saleslist?deliveryDate=${valideDate}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        window.open(response.data);
      })
      .catch((error) => handleApiErrors(error));
  };
};

export const changePicklistDate = (selectedDate) => {
  return { type: SELECT_PICKLIST_DATE, selectedDate };
};

export const loadOrdersDeliveryDates = () => {
  /*return dispatch => {
    axios
      .get(
        baseUrl +
          `orders?searchCriteria=&fields=items[extension_attributes[delivery_date,bl_shipping]]`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      )
      .then(response => {
        let data = response.data;
        let lstDeliveryDate = [];
        //Get delivery dates List from order (delivery_date OR bls)
        data.items.map(item => {
          let bls = item.extension_attributes.bl_shipping;
          if (bls.length > 0) {
            lstDeliveryDate.push(JSON.parse(bls[0]).deliverydate);
          } else if (item.extension_attributes.delivery_date) {

            lstDeliveryDate.push(item.extension_attributes.delivery_date);
          }
        });

        //Retreive delivery date distinct list
        let allDeliveryDates = [...new Set(lstDeliveryDate)];

        //Keep only futur dates
        let commingDeliveryDates = allDeliveryDates.filter(
          dDate => moment() <= moment(dDate),
        );
        dispatch({ type: LOAD_ORDERS_DELIVERY_DATE, commingDeliveryDates });
      })
      .catch(error => {
      });
  };*/
};
export const removeOrderInList = (selectedOrder) => {
  return { type: REMOVE_ORDER_IN_LIST, selectedOrder };
};
export const changingOrdersStatus = (isChanging) => {
  return { type: IS_CHANGING_ORDERS_STATUS, isChanging };
};

export const switchFilter = (filter) => {
  return { type: SWITCH_FILTER_ORDERS, filter };
};
export const changePage = (page) => {
  return { type: CHANGE_ORDERS_PAGE, page };
};
export const changeRowsPerPage = (itemsNb) => {
  return { type: CHANGE_ORDERS_PER_PAGE, itemsNb };
};
export const selectDeliveryDate = (day) => {
  return { type: SELECT_DELIVERY_DATE, day };
};

export const selectProduct = (products, id) => {
  //--Search inside products list
  let selectedRow = null;
  let product = products.filter((item) => item.id === id)[0];
  if (product) {
    product['cmdQte'] = 1;
    selectedRow = createRow(product);
  }
  return { type: SELECT_PRODUCT, product, selectedRow };
};

export const editSelectedProduct = (selectedProducts, selectedRows, id, qt) => {
  let updatedList = selectedProducts.map((item) => {
    if (item.id === id) {
      item.cmdQte = qt;
    }
    return item;
  });
  let updatedRows = selectedRows.map((item) => {
    if (item.id === id) {
      item.qeCmd = qt;
    }
    return item;
  });
  let total = selectedRows.reduce((accum, item) => accum + item.prixPcb * item.qeCmd, 0);
  return { type: EDIT_SELECTED_PRODUCT, updatedList, updatedRows, total };
};

export const updateTotal = (selectedRows) => {
  let total = selectedRows.reduce((accum, item) => accum + item.prixPcb * item.qeCmd, 0);
  return { type: UPDATE_TOTAL, total };
};

export const createOrder = (
  retailer,
  deliveryDate,
  total,
  selectedProducts,
  emptyCallback,
  shopEmptyCallback,
) => {
  //Parse selectedProducts to generate items
  let items = [];
  if (selectedProducts.length > 0) {
    for (let i = 0; i < selectedProducts.length; i++) {
      let item = {
        product_id: selectedProducts[i].id,
        sku: selectedProducts[i].sku,
        qty_ordered: selectedProducts[i].cmdQte,
        name: selectedProducts[i].name,
        original_price: selectedProducts[i].price,
        price: selectedProducts[i].price,
        product_type: 'simple',
      };
      items.push(item);
    }
  }

  return (dispatch) => {
    const token = getLocalStorage('id_token');

    dispatch(loadingData(true));
    let address = retailer.addresses[0];
    var data = JSON.stringify({
      entity: {
        email_sent: 1,
        base_currency_code: 'TND',
        order_currency_code: 'TND',
        store_id: 1,
        status: 'open',
        state: 'new',
        base_subtotal: total,
        subtotal: total,
        base_grand_total: total,
        customer_id: retailer.id,
        customer_email: retailer.email,
        customer_firstname: retailer.firstname,
        customer_lastname: retailer.lastname,
        grand_total: total,
        items: items,
        payment: {
          method: 'checkmo',
          amount_authorized: total,
          amount_ordered: total,
        },
        billing_address: {
          address_type: 'billing',
          city: address.city,
          company: address.company,
          country_id: address.country_id,
          customer_address_id: address.id,
          email: retailer.email, //putting retailer adress for now
          firstname: address.firstname,
          lastname: address.lastname,
          postcode: address.postcode,
          region: address.region.region_code,
          street: address.street,
          telephone: address.telephone,
        },
        extension_attributes: {
          delivery_date: deliveryDate,
          shipping_assignments: [
            {
              shipping: {
                address: {
                  address_type: 'shipping',
                  city: address.city,
                  company: address.company,
                  country_id: address.country_id,
                  customer_address_id: address.id,
                  email: retailer.email,
                  firstname: address.firstname,
                  lastname: address.lastname,
                  postcode: address.postcode,
                  region: address.region.region_code,
                  street: address.street,
                  telephone: address.telephone,
                },
                method: 'flatrate_flatrate',
                total: {
                  shipping_amount: 3,
                },
                extension_attributes: [],
              },
              extension_attributes: [],
            },
          ],
        },
      },
    });
    var config = {
      method: 'post',
      url: baseUrl + 'orders',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        axios({
          method: 'POST',
          url: baseUrl + 'orders/' + response.data.entity_id + '/emails',
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
          },
        })
          .then(function () {
            dispatch({ type: CREATE_ORDER });
            emptyCallback();
            shopEmptyCallback();
            dispatch(loadingData(false));
            alert('Order Successfully created!');
          })
          .catch(function (error) {
            dispatch(loadingData(false));
            handleApiErrors(error);
          });
      })
      .catch(function (error) {
        dispatch(loadingData(false));
        handleApiErrors(error);
      });
  };
};
export const clearFrom = () => {
  return { type: CLEAR_FORM };
};

export const deleteProduct = (id, lstProducts, lstRows, total) => {
  let product = lstProducts.find((item) => item.id === id);
  let SelectedProducts = lstProducts.filter((item) => item.id !== id);
  let SelectedRows = lstRows.filter((item) => item.id !== id);
  let newTotal = total - product.cmdQte * product.price;
  return { type: DELETE_PRODUCT, SelectedProducts, SelectedRows, newTotal };
};

function createRow(product) {
  //id,name, qtu,priceu,prixPcb,cmdQte
  let id = product.id;
  let name = product.name;
  let qtu = product.qtu;
  let prixu = product.pu;
  let prixPcb = product.price;
  let qeCmd = product.cmdQte;
  return { id, name, qtu, prixu, prixPcb, qeCmd };
}

/**
 * Fetchs orders from API and sets orders state by batches.
 * @prop {string}  selectedStatusFilter  The order's status filter.
 * @return {void} updates orders state zith orders data.
 */

const generatePinColor = () => {
  var allowed = 'ABCDEF0123456789';
  let S = '%23';
  while (S.length < 9) {
    S += allowed.charAt(Math.floor(Math.random() * 16 + 1));
  }
  return S;
};
export const getOrdersByDate = (deliveryDate) => {
  const token = getLocalStorage('id_token');

  return async (dispatch) => {
    dispatch(loadingData(true));
    await axios
      .get(baseUrl + `orders/list/per_delivery_date?deliveryDate=${deliveryDate}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then(async (response) => {
        let data = response.data;
        let pinColors = [];
        data.forEach((order) => {
          const count = _.countBy(data, (item) => {
            return (
              item.longitude != 0 &&
              item.latitude != 0 &&
              item.longitude === order.longitude &&
              item.latitude === order.latitude
            );
          });
          if (count.true > 1) {
            const randomNumber = Math.floor(Math.random() * Math.floor(100)) * 0.0001;
            order.longitude = order.longitude + randomNumber;
          }
          order.selected = false;
          order.hovered = false;
          let randomColor = '';
          if (order.delivery_agent.length > 0) {
            const agentIndex = pinColors.findIndex((item) => item.agent == order.delivery_agent);
            if (agentIndex >= 0) {
              randomColor = pinColors[agentIndex].pin_color;
            } else {
              randomColor = generatePinColor();
              pinColors.push({
                agent: order.delivery_agent,
                pin_color: randomColor,
              });
            }
            order.icon = `https://api.geoapify.com/v1/icon/?type=material&color=${randomColor}&size=large&apiKey=906ef766f6574a5987e8bc990d68cdd3`;
          } else {
            order.icon =
              'https://api.geoapify.com/v1/icon/?type=material&color=%23a49d9d&size=large&apiKey=906ef766f6574a5987e8bc990d68cdd3';
          }
        });
        dispatch({ type: GET_ORDERS_BY_DATE, data });
        dispatch(loadingData(false));
      })
      .catch((error) => handleApiErrors(error));
  };
};
export const selectOrderOnMap = (selectedOrder) => {
  return (dispatch) => {
    const ordersState = store.getState().ordersReducer;
    let orders = JSON.parse(JSON.stringify(ordersState.ordersByDate));
    const orderIndex = orders.findIndex((item) => item.order_id === selectedOrder.order_id);
    orders[orderIndex].selected = !orders[orderIndex].selected;
    dispatch({ type: SELECT_ORDER_ON_MAP, orders });
  };
};
export const hoverOrderOnMap = (hoveredOrder, openWindow) => {
  return (dispatch) => {
    const ordersState = store.getState().ordersReducer;
    let orders = JSON.parse(JSON.stringify(ordersState.ordersByDate));
    const orderIndex = orders.findIndex((item) => item.order_id === hoveredOrder.order_id);
    orders[orderIndex].hovered = openWindow;
    dispatch({ type: SELECT_ORDER_ON_MAP, orders });
  };
};
export const setDeliveryAgent = (agent, milkRun, deliveryDate) => {
  return (dispatch) => {
    dispatch(dispatchingOrders(true));
    const orders = store.getState().ordersReducer.ordersByDate;
    const ordersSelected = orders.filter((item) => item.selected);
    if (ordersSelected.length === 0) {
      alert('No orders selected');
      return;
    }

    let canceledTab = [];

    const promises = ordersSelected.map(async (order) => {
      const token = getLocalStorage('id_token');

      let config = {
        method: 'put',
        url: baseUrl + `orders/create`,
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
        data: {
          entity: {
            entity_id: order.order_id,
            state: order.state,
            status: order.status,
            extension_attributes: {
              delivery_agent: agent.firstname + ' ' + agent.lastname,
              delivery_agent_id: agent.id,
              delivery_slot: milkRun,
            },
          },
        },
      };

      await axios
        .get(baseUrl + `orders/${order.order_id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          let data = response.data;
          if (data.state === 'canceled') {
            canceledTab.push(order.order_id);
            alert('Some orders status are changed, we need to relaod the page');
            dispatch(getOrdersByDate(moment(deliveryDate).format('DD/MM/YYYY')));
            return Promise.reject(new Error('Problem!'));
          } else {
            axios(config);
          }
        })
        .catch((error) => handleApiErrors(error));
    });

    Promise.all(promises)
      .then(() => {
        alert('Milk-Runs has been created successfully');
        dispatch(dispatchingOrders(false));
        dispatch(getOrdersByDate(moment(deliveryDate).format('DD/MM/YYYY')));
      })
      .catch((error) => handleApiErrors(error));
  };
};

export const trackUserActionWithComment = (order, orderStatus, changeStatus = 1) => {
  const token = getLocalStorage('id_token');

  let promise = axios({
    method: 'post',
    url: baseUrl + `orders/${order.entity_id}/comments`,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: {
      statusHistory: {
        comment:
          changeStatus === 1
            ? localStorage.getItem('username') + ' Has changed order status to ' + orderStatus
            : orderStatus,
        entity_id: 0,
        entity_name: 'string',
        is_customer_notified: 0,
        is_visible_on_front: 0,
        parent_id: 0,
        status: order.status,
        extension_attributes: {},
      },
    },
  })
    .then((response) => {
      store.dispatch(loadingData(false));
    })
    .catch((error) => handleApiErrors(error));

  return promise;
};

export const verifBefore = async (order) => {
  const token = getLocalStorage('id_token');

  await axios
    .get(baseUrl + `orders/${order.order_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      let data = response.data;
      if (data.state === 'canceled') {
        alert('Some orders status are changed, we need to relaod the page');
        return Promise.reject(new Error('Problem!'));
      } else {
        alert('good');
        //do action
        //axios(config);
      }
    });
};

/**
 * =====Status VS State=======(Missing State Processing)
 * Open             || New
 * Valid            || New
 * Shipped          || New
 * Delivered & Paid || Complete
 * Archived         || Complete
 * Cancelled        || cancelled
 */
const lstStatus = [
  {
    status: 'open',
    state: 'new',
  },
  {
    status: 'valid',
    state: 'new',
  },
  {
    status: 'shipped',
    state: 'new',
  },
  {
    status: 'delivered', //Launch Invoice creation & ship events (state set auto)
    state: 'complete',
  },
  {
    status: 'Being_delivered',
    state: 'complete',
  },
  {
    status: 'unpaid',
    state: 'complete',
  },
  {
    status: 'archived',
    state: 'complete',
  },
  {
    status: 'failed',
    state: 'Cancelled',
  },
];

export const updateOrderStatus = (orders, status) => {
  const token = getLocalStorage('id_token');

  //alert("ici")
  if (status === 'canceled') {
    return (dispatch) => {
      dispatch(loadingData(true));
      dispatch(changingOrdersStatus(true));
      Promise.all(
        orders.map(async (order) => {
          await axios;
          await axios
            .get(baseUrl + `orders/${order.entity_id}`, {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            })
            .then((response) => {
              let data = response.data;
              if (data.state == 'complete') {
                alert('we cannot change complete order' + order.entity_id);
                window.location.reload();
              }
            });
          let promise = await axios({
            method: 'put',
            url: `${baseUrl}order/cancel/${order.entity_id}`,
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          })
            .then(() => {
              axios({
                method: 'post',
                url: baseUrl + `orders/${order.entity_id}/comments`,
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
                data: {
                  statusHistory: {
                    comment:
                      localStorage.getItem('username') + ' Has changed order status to canceled',
                    entity_id: 0,
                    entity_name: 'string',
                    is_customer_notified: 0,
                    is_visible_on_front: 0,
                    parent_id: 0,
                    status: 'failed',
                    extension_attributes: {},
                  },
                },
              })
                .then((response) => {
                  dispatch(removeOrderInList(order.entity_id));
                })
                .catch((error) => handleApiErrors(error));
            })
            .catch((error) => handleApiErrors(error));

          return promise;
        }),
      )
        .then((response) => {
          dispatch({ type: UPDATE_ORDERS_STATUS });
          dispatch(changingOrdersStatus(false));
          dispatch(loadingData(false));
        })
        .catch((error) => handleApiErrors(error));
    };
  } else if (status === 'delivered') {
    /**Two possible scenarios:
     * shipped to paid > generate invoice & ship
     * unpaid to paid > override order status & maintain state then generate invoice
     */
    if (orders[0].state === 'complete') {
      /**From unpaid TO paid */
      return (dispatch) => {
        dispatch(loadingData(true));
        dispatch(changingOrdersStatus(true));
        Promise.all(
          orders.map(async (order) => {
            /* let promise = await axios({
               method: 'put',
               url: baseUrl + 'orders/create',
               headers: {
                 Authorization: `Bearer ${token}`,
                 'Content-Type': 'application/json',
               },
               data: {
                 entity: {
                   entity_id: order.entity_id,
                   status: 'delivered',
                   state: 'complete',
                 },
               },
             });*/
            let promise = updateOrd(order, { status: 'delivered', state: 'complete' });
            promise.then(function (data) {
              dispatch(removeOrderInList(order.entity_id));
            });

            return promise;
          }),
        )
          .then((response) => {
            dispatch({ type: UPDATE_ORDERS_STATUS });
            dispatch(changingOrdersStatus(false));
            dispatch(loadingData(false));
          })
          .catch((error) => {
            dispatch(changingOrdersStatus(false));
            dispatch(loadingData(false));

            handleApiErrors(error);
          });
      };
    } else {
      /**From shipped TO paid */
      let entityIds = orders.map((item) => {
        return item.entity_id;
      });
      const orders_ids = entityIds.toString();
      var data = JSON.stringify({
        orders: orders_ids,
      });
      return (dispatch) => {
        dispatch(loadingData(true));
        dispatch(changingOrdersStatus(true));
        axios({
          method: 'post',
          url: baseUrl + `orders/manage/complete`,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          data: data,
        })
          .then((response) => {
            dispatch({ type: UPDATE_ORDERS_STATUS });
            dispatch(changingOrdersStatus(false));
            if (response.data.length == 0) {
              orders.forEach((order) => {
                dispatch(removeOrderInList(order.entity_id));
              });
              dispatch(loadingData(false));
            } else {
              const ordersDelivered = entityIds.filter((item) => {
                return response.data.includes(item);
              });
              ordersDelivered.forEach((order) => {
                dispatch(removeOrderInList(Number(order)));
              });
              dispatch(loadingData(false));
              const resData = response.data.toString();

              const errorMessage = formatOrderFailures(resData);

              alert(errorMessage);
            }
          })
          .catch((error) => handleApiErrors(error));
      };
    }
  } else if (status === 'unpaid') {
    //Order shipped but unpaid
    const entityIds = orders.map((item) => {
      return item.entity_id;
    });
    const orders_ids = entityIds.toString();
    var data = JSON.stringify({
      orders: orders_ids,
    });
    return (dispatch) => {
      dispatch(loadingData(true));

      dispatch(changingOrdersStatus(true));
      axios({
        method: 'post',
        url: baseUrl + `orders/manage/complete`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: data,
      })
        .then((response) => {
          dispatch({ type: UPDATE_ORDERS_STATUS });
          dispatch(changingOrdersStatus(false));
          if (response.data.length == 0) {
            Promise.all(
              orders.map(async (order) => {
                /*let promise = await axios({
                  method: 'put',
                  url: baseUrl + 'orders/create',
                  headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                  },
                  data: {
                    entity: {
                      entity_id: order.entity_id,
                      status: 'unpaid',
                      state: 'complete',
                    },
                  },
                });*/
                let promise = updateOrd(order, { status: 'unpaid', state: 'complete' });

                dispatch(removeOrderInList(order.entity_id));
                dispatch(removeOrderInList(order.entity_id));

                return promise;
              }),
            )
              .then((response) => {
                dispatch(loadingData(false));
              })
              .catch((error) => handleApiErrors(error));
          } else {
            const ordersDelivered = entityIds.filter((item) => {
              return response.data.includes(item);
            });
            ordersDelivered.forEach((order) => {
              dispatch(removeOrderInList(Number(order)));
            });
            dispatch(loadingData(false));

            const resData = response.data.toString();

            const errorMessage = formatOrderFailures(resData);

            alert(errorMessage);
          }
        })
        .catch((error) => handleApiErrors(error));
    };
  } else {
    let stateStatus = lstStatus.find((item) => item.status === status);

    if (stateStatus) {
      return (dispatch) => {
        dispatch(loadingData(true));

        dispatch(changingOrdersStatus(true));
        Promise.all(
          orders.map(async (order) => {
            let promise = updateOrd(order, stateStatus);

            dispatch(removeOrderInList(order.entity_id));

            return promise;
          }),
        )
          .then((response) => {
            dispatch({ type: UPDATE_ORDERS_STATUS });
            dispatch(changingOrdersStatus(false));
            dispatch(loadingData(false));
          })
          .catch((error) => handleApiErrors(error));
      };
    }
  }
};

export const updateOrd = async (order, stateStatus, changeStatus = 1, verif = true) => {
  const token = getLocalStorage('id_token');
  let goEdit = true;
  await axios
    .get(baseUrl + `orders/${order.entity_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      let data = response.data;
      if (data.state === 'canceled' && verif) {
        goEdit = false;
        alert('Some orders status are changed, we need to relaod the page');
        window.location.reload();
      } else {
        if (stateStatus.state === 'canceled') {
          goEdit = false;
          alert('we cannot change canceled order');
          window.location.reload();
        }
      }
    });

  if (goEdit === true) {
    await axios({
      method: 'put',
      url: baseUrl + 'orders/create',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        entity: {
          entity_id: order.entity_id,
          status: stateStatus.status,
          state: stateStatus.state,
        },
      },
    });
    axios({
      method: 'post',
      url: baseUrl + `orders/${order.entity_id}/comments`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        statusHistory: {
          comment:
            changeStatus === 1
              ? localStorage.getItem('username') +
                ' Has changed order status to ' +
                stateStatus.status
              : stateStatus.status,
          entity_id: 0,
          entity_name: 'string',
          is_customer_notified: 0,
          is_visible_on_front: 0,
          parent_id: 0,
          status: stateStatus.status,
          extension_attributes: {},
        },
      },
    })
      .then((response) => {})
      .catch((error) => {
        store.dispatch(loadingData(false));
        handleApiErrors(error);
      });
  }
};

export const updatingOrder = (isUpdating) => {
  return { type: UPDATING_ORDER, isUpdating };
};
export const updateOrder = (isUpdating) => {
  return { type: UPDATE_ORDERS_DETAILS, isUpdating };
};
export const updateOrderDetails = (order, deliveryDate, callback) => {
  const token = getLocalStorage('id_token');

  store.dispatch(updateOrder(true));
  axios({
    method: 'put',
    url: baseUrl + 'orders/create',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: {
      entity: {
        entity_id: order.entity_id,
        status: order.status,
        state: order.state,
        extension_attributes: {
          delivery_date: deliveryDate,
        },
      },
    },
  })
    .then((response) => {
      const title = 'نهار التوصيل تبدل';
      const message =
        'حريفنا العزيز كماندتك الي كانت باش توصلك نهار' +
        order.extension_attributes.delivery_date +
        ' باش تولي تخلطك نهار' +
        deliveryDate +
        ' نشكروك على ثيقتك فينا';
      const image = publicBaseUrl + deliveryDateImg;
      const body = [`{action: update order delivery date}`, `{ order_id: ${order.entity_id} }`];
      callback(response.data);
      trackUserActionWithComment(
        order,
        localStorage.getItem('username') +
          ' has updated delivery date for order : ' +
          order.entity_id +
          ' to : ' +
          deliveryDate,
        0,
      );
      pushRetailerNotification(order.customer_id, message, body, title, image);
    })
    .catch((error) => {
      store.dispatch(updateOrder(false));
      handleApiErrors(error);
    });
};

export const pushRetailerNotification = (retailerId, message, payload, event, imageUrl) => {
  const token = getLocalStorage('id_token');

  const data = JSON.stringify({
    event: event,
    payload: payload,
    message: message,
    imgUrl: imageUrl,
    topic: `testing_retailer_${retailerId}`,
  });
  axios({
    method: 'post',
    url: baseUrl + 'push/send',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: data,
  })
    .then((response) => {
      store.dispatch(updateOrder(false));
      alert('Delivery date updated and retailer is notified !');
    })
    .catch((error) => handleApiErrors(error));
};

export const updateOrderState = (entityID, status) => {
  const ordersState = store.getState().ordersReducer.orders;
  const orderToUpdate = ordersState.items.find((item) => item.entity_id === entityID);
  orderToUpdate.status = status;
  return ordersState;
};

/*export const generateOrderBls = orderid => {
  //Available Infos in listing (id_order,status,retailer_email,retailer_firstname,retailer_lastname,)
  //ShippingBill Object DATA
  //(order_id,idretailer)
  return dispatch => {
    const url = baseUrl + "kamioun-shippingbill/shippingbill";
    let shippingBill = {
      order_id: orderid,
      idretailer: "4",
    };
    axios({
      method: "post",
      url: url,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: { shippingbill: shippingBill },
    })
      .then(() => {
        dispatch({ type: GENERATE_BL_SINGLE_ORDER });
      })
      .catch(error => {
      });
  };
};*/
export const getAllBlsInOrder = () => {
  const token = getLocalStorage('id_token');

  return (dispatch) => {
    axios
      .get(baseUrl + `kamioun-shippingbill/shippingbill/search?searchCriteria=`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let data = response.data.items;
        dispatch({ type: GET_ALL_BLS, data });
      })
      .catch((error) => handleApiErrors(error));
  };
};

function getManufacturersPerOrder(orderItems, callback) {
  const token = getLocalStorage('id_token');

  //get Manufacturers per order

  let lstSkuList = orderItems.map((item) => {
    return item.sku;
  });
  let query = `products?searchCriteria[filterGroups][0][filters][0][condition_type]=in&searchCriteria[filterGroups][0][filters][0][field]=sku&searchCriteria[filterGroups][0][filters][0][value]=${lstSkuList.toString()}`;
  axios
    .get(baseUrl + query, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      //Generate manufacturers distinct list existing in order
      const distinctManufacturers = [
        ...new Set(
          response.data.items.map((item) => {
            return item.custom_attributes.find((elm) => elm.attribute_code === 'manufacturer')
              .value;
          }),
        ),
      ];
      callback(distinctManufacturers.toString());
    })
    .catch((error) => handleApiErrors(error));
}

//---Items in Array Grouped By specified criteria

//---Bill generation action creators
export const getOrderById = (id, callback) => {
  const token = getLocalStorage('id_token');

  return (dispatch) => {
    axios
      .get(baseUrl + `orders/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let data = response.data;
        let prods = data.items.map((item) => item.sku);
        callback(prods);
        dispatch({ type: GET_ORDER_BY_ID, data });
      })
      .catch((error) => handleApiErrors(error));
  };
};

export const getSingleOrderBls = (id) => {
  const token = getLocalStorage('id_token');

  return (dispatch) => {
    axios
      .get(
        baseUrl +
          `kamioun-shippingbill/shippingbill/search?searchCriteria[filterGroups][0][filters][0][condition_type]=eq&searchCriteria[filterGroups][0][filters][0][field]=order_id&searchCriteria[filterGroups][0][filters][0][value]=${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        let data = response.data;
        dispatch({ type: GET_BLS_FOR_ORDER, data });
      })
      .catch((error) => handleApiErrors(error));
  };
};
