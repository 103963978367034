import { combineReducers } from 'redux';
import { ordersReducer } from './orders';
import { productsReducer } from './products';
import { retailersReducer } from './retailers';
import { manufacturersReducer } from './manufacturers';
import { blsReducer } from './bls';
import { uiActionsReducer } from './uiActions';
import { signatureReducer } from './signature';
import { pricesReducer } from './prices';
import { dataPointsReducer } from './dataPoints';
import { chartsReducer } from './charts';

const appReducers = combineReducers({
  productsReducer,
  retailersReducer,
  uiActionsReducer,
  ordersReducer,
  manufacturersReducer,
  blsReducer,
  signatureReducer,
  pricesReducer,
  dataPointsReducer,
  chartsReducer,
});
export default appReducers;
