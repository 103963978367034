import axios from 'axios';
import _ from 'lodash';
import { GET_ORDERS_STATS, GET_GMVS_STATS, GET_CUSTOMERS_STATS } from './types';
import { loadingStats } from './uiActions';
import { getLocalStorage } from '../../hooks/useLocalStorage';
import { handleApiErrors } from '../../utils/handleApiErrors';

const baseUrl = process.env.REACT_APP_BASE_URL;

const token = getLocalStorage('id_token');

export const getChartsdata = () => {
  return async (dispatch) => {
    dispatch(loadingStats(true));
    await dispatch(getOrdersStats());
    await dispatch(getGmvsStats());
    await dispatch(getCustomersStats());
    dispatch(loadingStats(false));
  };
};

export const getOrdersStats = (today) => {
  return async (dispatch) => {
    await axios({
      method: 'get',
      url: baseUrl + `analytics/get_by_date_range?from=01/01/2023&to=${today}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        let responseData = response.data;
        let weeksToShow = responseData.weeks.slice(
          responseData.weeks.length - 10 < 0 ? 0 : responseData.weeks.length - 10,
          responseData.weeks.length,
        );
        let weeks = weeksToShow.map((element) => {
          return { x: element.key, y: element.value };
        });
        let monthsToShow = responseData.months.slice(
          responseData.months.length - 6 < 0 ? 0 : responseData.months.length - 6,
          responseData.months.length,
        );
        let months = monthsToShow.map((element) => {
          return { x: element.key, y: element.value };
        });
        let daysToShow = responseData.days.slice(
          responseData.days.length - 15 < 0 ? 0 : responseData.days.length - 15,
          responseData.days.length,
        );
        let days = daysToShow.map((element) => {
          return { x: element.key, y: element.value };
        });
        const data = {
          weeks,
          months,
          days,
          total: responseData.total,
        };
        dispatch({ type: GET_ORDERS_STATS, data });
      })
      .catch((error) => handleApiErrors(error));
  };
};

export const getGmvsStats = (today) => {
  return async (dispatch) => {
    await axios({
      method: 'get',
      url: baseUrl + `analytics/get_base_total_by_date_range?from=01/01/2023&to=${today}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        let responseData = response.data;
        let weeksToShow = responseData.weeks.slice(
          responseData.weeks.length - 10 < 0 ? 0 : responseData.weeks.length - 10,
          responseData.weeks.length,
        );
        let weeks = weeksToShow.map((element) => {
          return { x: element.key, y: element.value };
        });
        let monthsToShow = responseData.months.slice(
          responseData.months.length - 6 < 0 ? 0 : responseData.months.length - 6,
          responseData.months.length,
        );
        let months = monthsToShow.map((element) => {
          return { x: element.key, y: element.value };
        });
        let daysToShow = responseData.days.slice(
          responseData.days.length - 15 < 0 ? 0 : responseData.days.length - 15,
          responseData.days.length,
        );
        let days = daysToShow.map((element) => {
          return { x: element.key, y: element.value };
        });
        const data = {
          weeks,
          months,
          days,
          total: responseData.total,
        };
        dispatch({ type: GET_GMVS_STATS, data });
      })
      .catch((error) => handleApiErrors(error));
  };
};

export const getCustomersStats = (today) => {
  return async (dispatch) => {
    await axios({
      method: 'get',
      url: baseUrl + `analytics/get_customers_by_date_range?from=01/01/2023&to=${today}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        let responseData = response.data;
        let weeksToShow = responseData.weeks.slice(
          responseData.weeks.length - 10 < 0 ? 0 : responseData.weeks.length - 10,
          responseData.weeks.length,
        );
        let weeks = weeksToShow.map((element) => {
          return { x: element.key, y: element.value };
        });
        let monthsToShow = responseData.months.slice(
          responseData.months.length - 6 < 0 ? 0 : responseData.months.length - 6,
          responseData.months.length,
        );
        let months = monthsToShow.map((element) => {
          return { x: element.key, y: element.value };
        });
        let daysToShow = responseData.days.slice(
          responseData.days.length - 15 < 0 ? 0 : responseData.days.length - 15,
          responseData.days.length,
        );
        let days = daysToShow.map((element) => {
          return { x: element.key, y: element.value };
        });
        const data = {
          weeks,
          months,
          days,
          total: responseData.total,
        };
        dispatch({ type: GET_CUSTOMERS_STATS, data });
      })
      .catch((error) => handleApiErrors(error));
  };
};
