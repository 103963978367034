import {
  SELECT_CATEGORIE,
  LOAD_CATEGORIES,
  LOAD_MORE_CATEGORIES,
  CHANGE_PAGE,
  LOAD_DATA_POINTS,
  CHANGE_DATA_POINTS_PAGE,
  CHANGE_DATA_POINTS_ROWS_PER_PAGE,
} from '../actions/types';

const initialState = {
  categories: [],
  page: 0,
  total: 0,
  selectedCategorie: 0,
  itemsPerPage: 10,
  dataPoints: [],
  pageDataPoints: 0,
  totalDataPoints: 0,
  itemPerPageDataPoints: 10,
};

export const dataPointsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_CATEGORIE:
      return { ...state, selectedCategorie: action.categorieId };
    case LOAD_CATEGORIES:
      return {
        ...state,
        categories: action.data,
        total: action.data.total_count,
      };
    case LOAD_MORE_CATEGORIES:
      return {
        ...state,
        categories: [...state.categories, ...action.data],
        total: action.data.total_count,
      };
    case CHANGE_PAGE:
      return { ...state, page: action.page };
    case LOAD_DATA_POINTS:
      return {
        ...state,
        dataPoints: action.data.items,
        totalDataPoints: action.data.total_count,
      };
    case CHANGE_DATA_POINTS_PAGE:
      return { ...state, pageDataPoints: action.page };
    case CHANGE_DATA_POINTS_ROWS_PER_PAGE:
      return {
        ...state,
        itemPerPageDataPoints: action.rowsPerPage,
        pageDataPoints: 0,
      };
  }
  return state;
};
