import React, { useEffect } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import classnames from 'classnames';

// styles
import useStyles from './styles';

// router
import { useParams } from 'react-router-dom';

// logo
import logo from './logo.png';

// redux
import { useDispatch } from 'react-redux';
import { getSignedDoc } from '../../redux/actions/signature';

export default function Confirmation() {
  let classes = useStyles();
  const { doc_id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSignedDoc(doc_id));
  }, []);
  return (
    <Grid container className={classes.container}>
      <div className={classes.logotype}>
        <img className={classes.logotypeIcon} src={logo} alt="logo" />
        <Typography variant="h3" className={classes.logotypeText}>
          Kamioun
        </Typography>
      </div>
      <Paper classes={{ root: classes.paperRoot }}>
        <Typography color="primary" className={classnames(classes.textRow, classes.message)}>
          Confirmed BLs Signature
        </Typography>
        <Typography variant="h5" color="primary" className={classes.textRow}>
          You sucessfully signed the documents for Kamioun today’s operations!
        </Typography>
      </Paper>
    </Grid>
  );
}
