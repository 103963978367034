export function formatOrderFailures(inputString) {
    // Remove the "Orders failed :" part
    inputString = inputString.replace("Orders failed :", "").trim();

    // Split the string by commas to get individual product failures
    const failures = inputString.split(',');

    // Format each failure line
    const formattedFailures = failures.map(failure => failure.trim());

    // Join the formatted failures with newline characters for better readability
    const formattedString = formattedFailures.join('\n');

    return formattedString;
}
