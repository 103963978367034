/**
 * Splits the orders data into multiple queries to batch the data fetch.
 * @prop {number}  batchSize  The preferred size of the batch.
 * @prop {number}  ordersCount  The total data count.
 * @prop {string}  selectedStatusFilter  The order's status filter.
 * @prop {string}  baseUrl  The back-end base url.
 * @prop {string}  token  The user token.
 * @return {Array<String>} Returns an array of urls.
 */
export const getOrdersBatchQueries = (batchSize, ordersCount, selectedStatusFilter, baseUrl) => {
  // Compute number of queries
  const number_of_queries = Math.ceil(ordersCount / batchSize);
  // init queries array
  let queries = [];

  for (let i = 1; i <= number_of_queries; i++) {
    queries = [
      ...queries,
      baseUrl +
        (selectedStatusFilter !== 'allStatuses'
          ? `orders?searchCriteria[filterGroups][0][filters][0][field]=status&searchCriteria[filterGroups][0][filters][0][value]=${selectedStatusFilter}&searchCriteria[filterGroups][0][filters][0][conditionType]=eq&searchCriteria[sortOrders][0][field]=entity_id&searchCriteria[sortOrders][0][direction]=DESC&searchCriteria[pageSize]=${batchSize}&searchCriteria[currentPage]=${i}`
          : `orders?searchCriteria[sortOrders][0][field]=entity_id&searchCriteria[sortOrders][0][direction]=DESC&searchCriteria[pageSize]=${batchSize}&searchCriteria[currentPage]=${i}`),
    ];
  }
  // returns the urls array
  return queries;
};
