import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  widgetRoot: {
    boxShadow: theme.customShadows.widget,
    minHeight: '75vh',
  },
  datePicker: {
    position: 'relative',
    marginTop: 20,
    background: 'transparent',
    fontWeight: 100,
    padding: 20,
    margin: 20,
    cursor: 'pointer',
    textTransform: 'uppercase',
    borderWidth: 1,
    borderColor: 'blue',
    borderRadius: 6,
  },
  loadingCircle: {
    display: 'inline-Block',
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  picklistContainer: {
    flexDirection: 'row',
    display: 'flex',
    position: 'relative',
    '@media (max-width: 600px)': {
      flexDirection: 'column',
    },
  },
  infoWindowPrice: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '16px',
    textAlign: 'center',
    color: '#697479',
  },
  infoWindowTitle: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '16px',
    textAlign: 'center',
    color: '#697479',
  },
  sectionReport: {
    margin: 20,
  },
  formButtons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    float: 'right',
    width: '65%',
    margin: 10,
    marginRight: 30,
  },
  divCards: {
    position: 'relative',
    left: '2%',
    top: '-73%',
    width: 120,
    height: 20,
  },
  card: {
    boxShadow: `0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1)`,
    margin: 3,
    borderRadius: 36,
    border: '1px solid #CCCCCC',
  },
  cardText: {
    margin: 6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 12,
    fontFamily: 'Cairo',
    color: '#2E466E',
  },
  validateButton: {
    backgroundColor: '#2E466E',
    color: 'white',
    fontWeight: '600',
    width: '100%',
    margin: theme.spacing(2),
    '&:hover': {
      background: '#ffffff',
      color: '#2E466E',
      fontWeight: '600',
    },
  },
  cancelButton: {
    backgroundColor: 'white',
    color: '#2E466E',
    borderColor: '#2E466E',
    fontWeight: '600',
    width: '100%',
    margin: theme.spacing(2),
    '&:hover': {
      background: '#bfbfba',
      color: '#636360',
      fontWeight: '600',
    },
  },
}));
