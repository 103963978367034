import { FormControl, Grid, Select } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getOrdersStats, getGmvsStats, getCustomersStats } from '../../redux/actions/charts';
import { loadingStats } from '../../redux/actions/uiActions';
import './barRounded.js';

// styles
import useStyles from './styles';

export default function Charts() {
  let classes = useStyles();
  const dispatch = useDispatch();
  const chartState = useSelector((state) => state).chartsReducer;
  const uiActionsState = useSelector((state) => state).uiActionsReducer;
  const [statsOption, setStatsOption] = useState('week');

  useEffect(() => {
    const date = moment().add(1, 'days').format('DD/MM/YYYY');
    if (!chartState.orders) {
      dispatch(getOrdersStats(date));
    }
    if (!chartState.gmvs) {
      dispatch(getGmvsStats(date));
    }
    if (!chartState.customers) {
      dispatch(getCustomersStats(date));
    }
  }, []);

  const getArrayWithLength = (length, item, lastBarColor) => {
    let array = [];
    for (let i = 0; i < length; i++) {
      array.push(item);
    }
    if (lastBarColor) {
      array[array.length - 1] = lastBarColor;
    }
    return array;
  };

  const getBarState = (chart) => {
    const chartStateCopy = JSON.parse(JSON.stringify(chartState));
    const ordersData =
      statsOption === 'week'
        ? chartStateCopy.orders.weeks
        : statsOption === 'month'
          ? chartStateCopy.orders.months
          : chartStateCopy.orders.days;
    const gmvsData =
      statsOption === 'week'
        ? chartStateCopy.gmvs.weeks
        : statsOption === 'month'
          ? chartStateCopy.gmvs.months
          : chartStateCopy.gmvs.days;
    const customersData =
      statsOption === 'week'
        ? chartStateCopy.customers.weeks
        : statsOption === 'month'
          ? chartStateCopy.customers.months
          : chartStateCopy.customers.days;
    const data = chart === 'Orders' ? ordersData : chart === 'GMV' ? gmvsData : customersData;

    const maxBarsLength = statsOption === 'week' ? 10 : statsOption === 'month' ? 6 : 15;
    const ordersLength = ordersData?.length < maxBarsLength ? ordersData?.length : maxBarsLength;
    const gmvsLength = gmvsData?.length < maxBarsLength ? gmvsData?.length : maxBarsLength;
    const customersLength =
      customersData?.length < maxBarsLength ? customersData?.length : maxBarsLength;
    const barsLength =
      chart === 'Orders' ? ordersLength : chart === 'GMV' ? gmvsLength : customersLength;
    const barColor = chart === 'Orders' ? '#2E466E' : chart === 'GMV' ? '#E0B20E' : '#ADDEEC';

    let dataLabels = '';
    if (data) {
      dataLabels = data.map((element) => {
        return element.x;
      });
    }
    const state = {
      labels: dataLabels === '' ? getArrayWithLength(barsLength, '', false) : dataLabels,
      datasets: [
        {
          label: chart,
          backgroundColor:
            statsOption === 'week'
              ? getArrayWithLength(barsLength, '#C6CACC', barColor)
              : statsOption === 'month'
                ? getArrayWithLength(barsLength, '#C6CACC', barColor)
                : getArrayWithLength(barsLength, '#C6CACC', barColor),
          hoverBackgroundColor: barColor,
          borderColor: 'rgba(0,0,0,1)',
          borderWidth: 0,
          minBarLength: 10,
          barThickness: 6,
          data: chart === 'Orders' ? ordersData : chart === 'GMV' ? gmvsData : customersData,
        },
      ],
    };
    return state;
  };

  const handleChange = (event) => {
    setStatsOption(event.target.value);
  };

  return (
    <>
      {uiActionsState.chartsLoader ? (
        <div className={classes.loadingCircle}>
          <CircularProgress />
        </div>
      ) : (
        <Grid container spacing={1} className={classes.container}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={3}>
              <div className={classes.barStyle}>
                <div className={classes.chartInfos}>
                  <p className={classes.orderBarValue}>
                    {chartState.orders?.weeks?.length
                      ? statsOption === 'week'
                        ? chartState?.orders?.weeks[chartState?.orders?.weeks?.length - 1].y
                        : statsOption === 'month'
                          ? chartState?.orders?.months[chartState?.orders?.months?.length - 1].y
                          : chartState?.orders?.days[chartState?.orders?.days?.length - 1].y
                      : null}
                  </p>
                  <p className={classes.barTitle}>Orders this {statsOption}</p>
                </div>
                <Bar
                  data={() => getBarState('Orders')}
                  options={{
                    type: 'bar',
                    responsive: true,
                    cornerRadius: 7,
                    title: {
                      display: false,
                    },
                    legend: {
                      display: false,
                    },
                    scales: {
                      xAxes: [
                        {
                          barThickness: 15,
                          gridLines: {
                            display: false,
                          },
                          ticks: {
                            display: false,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          ticks: {
                            display: false,
                            beginAtZero: false,
                            min: 0,
                          },
                          gridLines: {
                            display: false,
                          },
                        },
                      ],
                    },
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div className={classes.barStyle}>
                <div className={classes.chartInfos}>
                  <p className={classes.gmvBarValue}>
                    {chartState.gmvs?.weeks?.length
                      ? statsOption === 'week'
                        ? chartState?.gmvs?.weeks[chartState?.gmvs?.weeks?.length - 1].y?.toFixed(0)
                        : statsOption === 'month'
                          ? chartState?.gmvs?.months[
                              chartState?.gmvs?.months?.length - 1
                            ].y?.toFixed(0)
                          : chartState?.gmvs?.days[chartState?.gmvs?.days?.length - 1].y?.toFixed(0)
                      : null}
                  </p>
                  <p className={classes.barTitle}>GMV this {statsOption}</p>
                </div>
                <Bar
                  data={() => getBarState('GMV')}
                  options={{
                    type: 'bar',
                    responsive: true,
                    cornerRadius: 7,
                    title: {
                      display: false,
                    },
                    legend: {
                      display: false,
                    },
                    tooltips: {},
                    scales: {
                      xAxes: [
                        {
                          barThickness: 15,
                          gridLines: {
                            display: false,
                          },
                          ticks: {
                            display: false,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          ticks: {
                            display: false,
                            beginAtZero: false,
                            min: 0,
                          },
                          gridLines: {
                            display: false,
                          },
                        },
                      ],
                    },
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div className={classes.barStyle}>
                <div className={classes.chartInfos}>
                  <p className={classes.customerBarValue}>
                    {chartState.customers?.weeks?.length
                      ? statsOption === 'week'
                        ? chartState?.customers?.weeks[chartState?.customers?.weeks?.length - 1].y
                        : statsOption === 'month'
                          ? chartState?.customers?.months[chartState?.customers?.months?.length - 1]
                              .y
                          : chartState?.customers?.days[chartState?.customers?.days?.length - 1].y
                      : null}
                  </p>
                  <p className={classes.barTitle}>Customers this {statsOption}</p>
                </div>
                <Bar
                  data={() => getBarState('Customers')}
                  options={{
                    type: 'bar',
                    responsive: true,
                    cornerRadius: 7,
                    title: {
                      display: false,
                    },
                    legend: {
                      display: false,
                    },
                    tooltips: {},
                    scales: {
                      xAxes: [
                        {
                          barThickness: 15,
                          gridLines: {
                            display: false,
                          },
                          ticks: {
                            display: false,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          ticks: {
                            display: false,
                            beginAtZero: false,
                            min: 0,
                          },
                          gridLines: {
                            display: false,
                          },
                        },
                      ],
                    },
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select native value={statsOption} onChange={handleChange}>
                  <option value={'week'}>By week</option>
                  <option value={'month'}>By month</option>
                  <option value={'day'}>By day</option>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
