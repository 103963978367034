export const handleApiErrors = (error) => {
  console.error("🚀 ~ handleApiErrors ~ error:", error)
  if (error?.response?.status == 500) {
    alert('Failed To Terform Action Due To Server Error');
  }
  if (error?.response?.status == 401) {
    alert('You Don’t Have The Permissions For This Action !');
  }
  alert('Error .... ! ');
};
