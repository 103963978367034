export const CHANGE_DATA_POINTS_ROWS_PER_PAGE = 'CHANGE_DATA_POINTS_ROWS_PER_PAGE';
export const CHANGE_DATA_POINTS_PAGE = 'CHANGE_DATA_POINTS_PAGE';
export const SEC_LOADING = 'SEC_LOADING';
export const LOAD_DATA_POINTS = 'LOAD_DATA_POINTS';
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const LOAD_CATEGORIES = 'LOAD_CATEGORIES';
export const LOAD_MORE_CATEGORIES = 'LOAD_MORE_CATEGORIES';
export const SELECT_CATEGORIE = 'SELECT_CATEGORIE';
export const GET_ORDERS = 'GET_ORDERS';
export const GET_ORDERS_PART = 'GET_ORDERS_PART';
export const CLEAR_ORDERS = 'CLEAR_ORDERS';
export const UPDATE_ORDERS_STATUS = 'UPDATE_ORDERS_STATUS';
export const LOADING_DATA = 'LOADING_DATA';
export const LOADING_DATA_COMPLETE = 'LOADING_DATA_COMPLETE';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
export const CHANGE_PRODUCTS_PAGE = 'CHANGE_PRODUCTS_PAGE';
export const SEARCH_PRODUCT = 'SEARCH_PRODUCT';
export const SET_ITEMS_PER_PAGE = 'SET_ITEMS_PER_PAGE';
export const GET_RETAILERS = 'GET_RETAILERS';
export const SEARCH_RETAILER = 'SEARCH_RETAILER';
export const SELECT_RETAILER = 'SELECT_RETAILER';
export const SELECT_PRODUCT = 'SELECT_PRODUCT';
export const EDIT_SELECTED_PRODUCT = 'EDIT_SELECTED_PRODUCT';
export const SELECT_DELIVERY_DATE = 'SELECT_DELIVERY_DATE';
export const ADD_ORDER = 'ADD_ORDER';
export const UPDATE_TOTAL = 'UPDATE_TOTAL';
export const CREATE_ORDER = 'CREATE_ORDER';
export const CLEAR_FORM = 'CLEAR_FORM';
export const CLEAR_RETAILER = 'CLEAR_RETAILER';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const GET_MANUFACTURERS = 'GET_MANUFACTURERS';
export const GET_BLS = 'GET_BLS';
export const GET_PRODUCT_BY_ORDER = 'GET_PRODUCTS_BY_ORDER';
export const SELECT_BATCH_BL = 'SELECT_BATCH_BL';
export const LOADING_PRODUCTS = 'LOADING_PRODUCTS';
export const GENERATE_BL_SINGLE_ORDER = 'GENERATE_BL_SINGLE_ORDER';
export const GET_ALL_BLS = 'GET_ALL_BLS';
export const GET_ORDER_BY_ID = 'GET_ORDER_BY_ID';
export const GET_BLS_FOR_ORDER = 'GET_BLS_FOR_ORDER';
export const GET_PRODUCTS_BY_LIST_SKU = 'GET_PRODUCTS_BY_LIST_SKU';
export const GET_BL_BY_ID = 'GET_BL_BY_ID';
export const OPEN_DELETE_MENU = 'OPEN_DELETE_MENU';
export const CLOSE_DELETE_MENU = 'CLOSE_DELETE_MENU';
export const UPDATE_SHIPPING_BILLS = 'UPDATE_SHIPPING_BILLS';
export const UPDATE_MANUFACTURER_BLS = 'UPDATE_MANUFACTURER_BLS';
export const GET_BLS_BY_ORDER = 'GET_BLS_BY_ORDER';
export const CREATE_DOC = 'CREATE_DOC';
export const DOC_SIGNED = 'DOC_SIGNED';
export const CHANGE_ORDERS_PAGE = 'CHANGE_ORDERS_PAGE';
export const CHANGE_ORDERS_PER_PAGE = 'CHANGE_ORDERS_PER_PAGE';
export const SWITCH_FILTER_ORDERS = 'SWITCH_FILTER_ORDERS';
export const IS_CHANGING_ORDERS_STATUS = 'IS_CHANGING_ORDERS_STATUS';
export const REMOVE_ORDER_IN_LIST = 'REMOVE_ORDER_IN_LIST';
export const LOAD_ORDERS_DELIVERY_DATE = 'LOAD_ORDERS_DELIVERY_DATE';
export const SELECT_PICKLIST_DATE = 'SELECT_PICKLIST_DATE';
export const GENERATE_PICKLIST = 'GENERATE_PICKLIST';
export const GET_PRODUCTS_BY_CATEGORY = 'GET_PRODUCTS_BY_CATEGORY';
export const CREATE_RETAILER = 'CREATE_RETAILER';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const ADD_PRICE = 'ADD_PRICE';
export const UPDATE_ORDERS_DETAILS = 'UPDATE_ORDERS_DETAILS';
export const UPDATING_ORDER = 'UPDATING_ORDER';
export const EDIT_ORDER = 'EDIT_ORDER';
export const SERVER_SIDE = 'SERVER_SIDE';
export const GET_AGENT_REPORT = 'GET_AGENT_REPORT';
export const GET_SALES_LIST = 'GET_SALES_LIST';
export const LOADING_REPORT = 'LOADING_REPORT';
export const GET_AGENTS = 'GET_AGENTS';
export const GET_ORDERS_STATS = 'GET_ORDERS_STATS';
export const GET_GMVS_STATS = 'GET_GMVS_STATS';
export const GET_CUSTOMERS_STATS = 'GET_CUSTOMERS_STATS';
export const LOADING_CHARTS_STATS = 'LOADING_CHARTS_STATS';
export const GET_ORDERS_BY_DATE = 'GET_ORDERS_BY_DATE';
export const SELECT_ORDER_ON_MAP = 'SELECT_ORDER_ON_MAP';
export const DISPATCHING_ORDERS = 'DISPATCHING_ORDERS';
export const GET_PRODUCTS_TO_SHIP = 'GET_PRODUCTS_TO_SHIP';
export const GET_ORDER_PRODUCTS = 'GET_ORDER_PRODUCTS';
export const UPDATE_SHIPPED_PRODUCTS = 'UPDATE_SHIPPED_PRODUCTS';
export const LOADING_ORDER_PRODUCTS = 'LOADING_ORDER_PRODUCTS';
export const CLEAR_ORDER_PRODUCTS = 'CLEAR_ORDER_PRODUCTS';
