import React, { useState, useEffect } from 'react';

// packages
import _ from 'lodash';

//styles
import useStyles from './styles';

// components
import CustomWidget from '../../components/CustomWidget';
import { CopyToClipboard } from 'react-copy-to-clipboard';

// material ui components
import {
  Paper,
  Grid,
  Button,
  TextField,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
  TableFooter,
  Typography,
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import { PictureAsPdf as Pdf } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import Tooltip from '@material-ui/core/Tooltip';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { updateBlsStatus } from '../../redux/actions/bls';
import { getManufacturers } from '../../redux/actions/manufacturers';

const entId = process.env.REACT_APP_ENT_ID;

export default function Bls() {
  let classes = useStyles();

  // Global State
  const manufacturersState = useSelector((state) => state).manufacturersReducer;
  const uiActionsState = useSelector((state) => state).uiActionsReducer;
  const dispatch = useDispatch();

  // Local State
  const [filterClicked, setFilter] = useState('without_number');
  const [rowSelected, setRowSelected] = useState(0);
  const [batchData, setBatchData] = useState({});
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [total, setTotal] = useState(0);
  const [valueCopied, setValueCopied] = useState('');
  const [copied, setCopied] = useState(false);

  async function fetchData() {
    await dispatch(
      getManufacturers((data) => {
        handleFilterClick('without_number', data.items);
      }),
    );
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async (batchBLs) => {
    const blFound = batchBLs.shipping_bl.find(
      (bl) => bl.entity_ref === undefined || bl.entity_ref === null || bl.entity_ref.length < 6,
    );
    if (blFound) {
      alert('please check BLs numbers');
    } else {
      await dispatch(updateBlsStatus(batchBLs, 'signature_missing'));
    }
  };

  const handleBlsNumbersChange = (blId, blNumber, bls) => {
    let updatedBatch = batchData;
    bls.map((item) => {
      if (item.shippingbill_id === blId) {
        item.entity_ref = blNumber;
      }
    });
    setBatchData(updatedBatch);
  };

  useEffect(() => {
    handleFilterClick(filterClicked);
  }, [manufacturersState.manufacturers.items]);
  const handleFilterClick = (state, _data) => {
    setFilter(state);
    let batchBLs = [];
    !_data
      ? (batchBLs = _.cloneDeep(manufacturersState.manufacturers.items))
      : (batchBLs = _.cloneDeep(_data));
    if (state !== 'all') {
      batchBLs.map((item) => {
        item.shipping_bl = item.shipping_bl.filter((bl) => bl.status === state);
      });
      _.remove(batchBLs, (item) => {
        return item.shipping_bl.length === 0;
      });
    }
    setPage(0);
    setRowsPerPage(5);
    setData(batchBLs);
    setBatchData(batchBLs[0]);
    setTotal(calculateTotal(batchBLs[0]));
    batchBLs[0] ? setRowSelected(batchBLs[0]?.manufacturer_id) : setRowSelected(0);
    setCopied(false);
  };

  const calculateTotal = (data) => {
    let total = 0;
    if (data && data.shipping_bl) {
      data.shipping_bl.forEach((bl) => {
        const blType = typeof bl.total;
        if (blType === 'string') {
          total += parseFloat(bl.total);
        }
      });
    } else if (batchData && batchData.shipping_bl) {
      batchData.shipping_bl.forEach((bl) => {
        const blType = typeof bl.total;
        if (blType === 'string') {
          total += parseFloat(bl.total);
        }
      });
    }
    return total;
  };

  useEffect(() => {
    if (batchData && batchData.shipping_bl) {
      let allUrls = '';
      batchData.shipping_bl.forEach((bl) => {
        const url = `https://shadoc.io/pub/corporateSign?entId=${entId}&dossierId=${bl.doc_id}&signataire=mariem.mohamed121991@gmail.com&redirectUrl=https://backoffice.kamioun.com/pub/apps/oms/build/index.html#/app/confirm-signature/${bl.doc_id}`;
        allUrls += url + '\n';
      });
      setValueCopied(allUrls);
    }
  }, [batchData]);
  const onCopy = () => {
    setCopied(true);
  };

  const generatePdfData = (data) => {
    window.open(data.url_shipping_bil);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Paper style={{ height: '100%' }}>
            <CustomWidget title="Client Signatures" disableWidgetMenu>
              <div>
                <div className={classes.box}>
                  <Button
                    variant="outlined"
                    color={filterClicked === 'without_number' ? 'primary' : 'default'}
                    className={classes.btn_filter}
                    onClick={() => handleFilterClick('without_number')}
                  >
                    Without N°
                    {filterClicked === 'without_number' ? (
                      <span>
                        <DoneIcon className={classes.doneIcon} />
                      </span>
                    ) : null}
                  </Button>
                  <Button
                    variant="outlined"
                    color={filterClicked === 'signature_missing' ? 'primary' : 'default'}
                    className={classes.btn_filter}
                    onClick={() => handleFilterClick('signature_missing')}
                  >
                    Signature missing
                    {filterClicked === 'signature_missing' ? (
                      <span>
                        <DoneIcon className={classes.doneIcon} />
                      </span>
                    ) : null}
                  </Button>
                  <Button
                    variant="outlined"
                    color={filterClicked === 'cancel' ? 'primary' : 'default'}
                    className={classes.btn_filter}
                    onClick={() => handleFilterClick('cancel')}
                  >
                    Canceled
                    {filterClicked === 'cancel' ? (
                      <span>
                        <DoneIcon className={classes.doneIcon} />
                      </span>
                    ) : null}
                  </Button>
                  <Button
                    variant="outlined"
                    color={filterClicked === 'signed' ? 'primary' : 'default'}
                    className={classes.btn_filter}
                    onClick={() => handleFilterClick('signed')}
                  >
                    Completed
                    {filterClicked === 'signed' ? (
                      <span>
                        <DoneIcon className={classes.doneIcon} />
                      </span>
                    ) : null}
                  </Button>
                  <Button
                    variant="outlined"
                    color={filterClicked === 'all' ? 'primary' : 'default'}
                    className={classes.btn_filter}
                    onClick={() => handleFilterClick('all')}
                  >
                    All
                    {filterClicked === 'all' ? (
                      <span>
                        <DoneIcon className={classes.doneIcon} />
                      </span>
                    ) : null}
                  </Button>
                </div>
              </div>
              {uiActionsState.isLoading ? (
                <div className={classes.loadingCircle}>
                  <CircularProgress />
                </div>
              ) : (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              backgroundColor: 'transparent',
                              color: 'rgba(0,0,0,0.42)',
                              borderBottom: '2px solid #d7d7d7',
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((row) => {
                        let currentId = row.manufacturer_id;
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={currentId}
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setRowSelected(row.manufacturer_id);
                              setBatchData(row);
                              setCopied(false);
                              setTotal(calculateTotal(row));
                            }}
                          >
                            {columns.map((column) => {
                              const value = row[column.id];
                              if (column.id === 'company_name') {
                                return (
                                  <TableCell align={column.align} key={column.id}>
                                    <Typography
                                      style={{ fontWeight: 'bold' }}
                                      className={
                                        rowSelected === row.manufacturer_id
                                          ? classes.rowTypoClicked
                                          : classes.clientTypo
                                      }
                                    >
                                      {value}
                                    </Typography>
                                    <Typography
                                      className={
                                        rowSelected === row.manufacturer_id
                                          ? classes.rowTypoClicked
                                          : classes.rowTypo
                                      }
                                    >
                                      {row.shipping_bl?.length} BLs{' '}
                                      {filterClicked === 'signMissing'
                                        ? `- sent on ${row.shipping_bl[0]?.sentondate}`
                                        : null}
                                    </Typography>
                                  </TableCell>
                                );
                              } else {
                                return (
                                  <TableCell align={column.align} key={column.id}>
                                    <Typography
                                      className={
                                        rowSelected === row.manufacturer_id
                                          ? classes.rowTypoClicked
                                          : classes.rowTypo
                                      }
                                    >
                                      {column.id === 'deliverydate'
                                        ? row.shipping_bl[0]?.deliverydate
                                        : value}
                                    </Typography>
                                  </TableCell>
                                );
                              }
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </CustomWidget>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          {batchData === undefined ? (
            <Paper style={{ height: '100%' }}>
              <CustomWidget />
            </Paper>
          ) : (
            <Paper style={{ height: '100%' }}>
              {uiActionsState.isLoading ? (
                <CustomWidget>
                  <div className={classes.loadingCircle}>
                    <CircularProgress />
                  </div>
                </CustomWidget>
              ) : (
                <CustomWidget
                  title={
                    batchData.shipping_bl !== undefined
                      ? filterClicked === 'signature_missing' &&
                        batchData.shipping_bl[0]?.sentondate !== undefined
                        ? batchData.company_name + `- ${batchData.shipping_bl[0]?.sentondate}`
                        : batchData.company_name
                      : 'Client'
                  }
                  option={
                    batchData.shipping_bl !== undefined
                      ? batchData?.shipping_bl.length > 1
                        ? batchData?.shipping_bl.length + ' BLs'
                        : batchData?.shipping_bl.length + ' BL'
                      : '0 BL'
                  }
                >
                  <div>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <div className={classes.container}>
                          {filterClicked === 'signature_missing' ? (
                            <div align="right" className={classes.copyButton}>
                              <CopyToClipboard text={valueCopied} onCopy={onCopy}>
                                <Tooltip title={copied ? 'Copied' : 'Copy to clipboard'}>
                                  <Button
                                    size="small"
                                    className={
                                      copied
                                        ? classes.clipBoardButtonCopied
                                        : classes.clipBoardButton
                                    }
                                  >
                                    {copied ? 'Copied' : 'Copy'}
                                  </Button>
                                </Tooltip>
                              </CopyToClipboard>
                            </div>
                          ) : null}
                          <div className={classes.signatureState}>
                            <Typography>Orders</Typography>
                            {filterClicked === 'signature_missing' ? (
                              <Chip label="Not signed" className={classes.blsNotSigned} />
                            ) : filterClicked === 'signed' ? (
                              <Chip label="All signed" className={classes.blsAllSigned} />
                            ) : null}
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} style={{ padding: 0 }}>
                        <Table className={classes.oTable} aria-label="spanning table">
                          <TableBody>
                            {batchData?.shipping_bl
                              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              .map((row) => {
                                if (
                                  filterClicked !== 'signature_missing' &&
                                  filterClicked !== 'signed'
                                ) {
                                  return (
                                    <TableRow
                                      key={row.url_shipping_bil}
                                      className={classes.blsOrders}
                                    >
                                      <TableCell className={classes.retailerName}>
                                        {row.retailer_firstname + ' ' + row.retailer_lastname}
                                      </TableCell>
                                      <TableCell className={classes.pdfIcon}>
                                        <Pdf
                                          style={{ cursor: 'pointer' }}
                                          onClick={() => {
                                            generatePdfData(row);
                                          }}
                                        />
                                      </TableCell>
                                      {row.status === 'signature_missing' ? (
                                        <TableCell align="right" className={classes.notSigned}>
                                          <Typography>Not signed</Typography>
                                        </TableCell>
                                      ) : (
                                        <TableCell align="right" className={classes.blNumber}>
                                          <TextField
                                            className={classes.numberField}
                                            id={row.shippingbill_id}
                                            label="BL N°"
                                            variant="outlined"
                                            disabled={filterClicked === 'signature_missing'}
                                            defaultValue={
                                              row.entity_ref === '_' ? '' : row.entity_ref
                                            }
                                            onChange={(event) => {
                                              const re = /^[0-9\b]+$/; //To only accept digits for BL Number
                                              if (
                                                event.target.value === '' ||
                                                re.test(event.target.value)
                                              ) {
                                                handleBlsNumbersChange(
                                                  row.shippingbill_id,
                                                  event.target.value,
                                                  batchData.shipping_bl,
                                                );
                                              } else {
                                                alert('BL Number can only contain digits');
                                              }
                                            }}
                                          />
                                        </TableCell>
                                      )}
                                    </TableRow>
                                  );
                                } else {
                                  return (
                                    <TableRow
                                      key={row.url_shipping_bil}
                                      className={classes.blsOrders}
                                    >
                                      <TableCell className={classes.retailerName}>
                                        {row.retailer_firstname + ' ' + row.retailer_lastname}
                                      </TableCell>
                                      <TableCell className={classes.emptyCell} />
                                      <TableCell align="right" className={classes.docIcon}>
                                        <InsertDriveFileOutlinedIcon
                                          align={'right'}
                                          style={{ cursor: 'pointer' }}
                                          onClick={() => {
                                            generatePdfData(row);
                                          }}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  );
                                }
                              })}
                          </TableBody>
                          <TableFooter>
                            <TableRow>
                              <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                colSpan={3}
                                count={
                                  batchData && batchData.shipping_bl
                                    ? batchData?.shipping_bl?.length
                                    : 0
                                }
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                  inputProps: { 'aria-label': '' },
                                  native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                              />
                            </TableRow>
                          </TableFooter>
                        </Table>
                        <Table className={classes.oTable} aria-label="spanning table">
                          <TableBody>
                            <TableRow>
                              <TableCell className={classes.borderedCell}>
                                <Typography className={classes.total}>Total</Typography>
                              </TableCell>
                              <TableCell className={classes.borderedCell} />
                              <TableCell align="right" className={classes.borderedCell}>
                                <Typography color="primary" className={classes.totalValue}>
                                  {total?.toFixed(3) + ' TND'}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                  </div>
                  <div>
                    <div className={classes.billingInfo}>
                      <div>
                        <Typography className={classes.billingLabel}>Billing System</Typography>
                      </div>
                      <div>
                        <Typography className={classes.billingSystem}>
                          {batchData?.billing}
                        </Typography>
                      </div>
                    </div>
                    <div className={classes.clientInfo}>
                      <div>
                        <Typography className={classes.billingLabel}>Contact</Typography>
                      </div>
                      <div>
                        <Typography color="primary" align="right" className={classes.clientPhone}>
                          {batchData?.phone_number}
                        </Typography>
                        <Typography align="right" className={classes.contactName}>
                          {batchData?.contact_name}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div style={{ position: 'relative' }}>
                    {filterClicked === 'without_number' ? (
                      <Button
                        className={classes.btnPrimary}
                        type="submit"
                        fullWidth
                        onClick={() => {
                          manufacturersState.manufacturers && handleSubmit(batchData);
                        }}
                      >
                        SEND BLS FOR SIGNATURE
                      </Button>
                    ) : null}
                  </div>
                </CustomWidget>
              )}
            </Paper>
          )}
        </Grid>
      </Grid>
    </>
  );
}

// BLs Table Columns
const columns = [
  {
    id: 'company_name',
    label: 'BLs in  batch',
    minWidth: 300,
  },
  {
    id: 'impact',
    label: 'Orders impact',
    align: 'right',
  },
  {
    id: 'deliverydate',
    label: 'Delivery Date',
    align: 'right',
  },
  {
    id: 'billing',
    label: 'Billing System',
    align: 'right',
  },
];
