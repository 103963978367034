//TODO: #RETAILERS# REDUCER
import {
  GET_RETAILERS,
  SELECT_RETAILER,
  CLEAR_RETAILER,
  CREATE_RETAILER,
  GET_AGENTS,
} from '../actions/types';

const initialState = {
  selectedRetailer: null,
  retailers: [],
  retailerAdded: {},
  agents: [],
};

export const retailersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RETAILERS:
      return { ...state, retailers: action.data.items };
    case CREATE_RETAILER:
      return { ...state, retailerAdded: action.data };
    case SELECT_RETAILER:
      return { ...state, selectedRetailer: action.retailer };
    case CLEAR_RETAILER:
      return { ...state, selectedRetailer: null };
    case GET_AGENTS:
      return { ...state, agents: action.data };
  }
  return state;
};
