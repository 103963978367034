import axios from 'axios';
import { CREATE_DOC, DOC_SIGNED } from './types';
import pdf2base64 from 'pdf-to-base64';
import { getManufacturers } from './manufacturers';
import { handleApiErrors } from '../../utils/handleApiErrors';

const baseUrl = process.env.REACT_APP_BASE_URL;
const entrepriseId = process.env.REACT_APP_ENTREPRISE_ID;

export const createDoc = (batchBls) => {
  return async (dispatch) => {
    let pdfs = [];
    batchBls.shipping_bl.forEach(async (bl) => {
      let file = {};
      file.size = null;
      file.type = 'pdf';
      file.name = `${batchBls.company_name}_${bl.entity_ref}.pdf`;
      await pdf2base64(bl.url_shipping_bil)
        .then((response) => {
          file.bytes = response;
        })
        .catch((error) => {});
      pdfs.push(file);
    });
    const docObj = {
      param: {
        label: `${batchBls.company_name}_bls.pdf`,
        entrepriseId: entrepriseId,
        files: pdfs,
        typeDocument: 'PDF',
        listedSignataires: [
          {
            ordre: 0,
            email: batchBls.email,
            currentPageNo: 1,
            signatureOriginX: 215,
            signatureOriginY: 215,
            signatureHeight: 100,
            signatureWidth: 200,
          },
        ],
        ordered: true,
        notificationSignataires: true,
        complete: false,
      },
    };
    await axios({
      method: 'post',
      url: baseUrl + 'kamioun-shadoc/shadoc',
      headers: {
        'Content-Type': 'application/json',
      },
      data: docObj,
    })
      .then((response) => {
        let data = response.data;
        dispatch({ type: CREATE_DOC, data: data.doc_id });
      })
      .catch((error) => handleApiErrors(error));
  };
};

export const getSignedDoc = (docID) => {
  return async (dispatch) => {
    axios({
      method: 'get',
      url: baseUrl + `kamioun-shadoc/shadoc/getdoc?doc_id=${docID}`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(async (response) => {
        let data = response.data;
        if (data.complete) {
          dispatch(docSigned(true));
          await dispatch(getManufacturers());
        }
      })
      .catch((error) => handleApiErrors(error));
  };
};

export const docSigned = (state) => {
  return { type: DOC_SIGNED, signed: state };
};
