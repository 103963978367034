import axios from 'axios';
import { ADD_PRICE } from './types';
import { getLocalStorage } from '../../hooks/useLocalStorage';
import { handleApiErrors } from '../../utils/handleApiErrors';

const baseUrl = process.env.REACT_APP_BASE_URL;

const token = getLocalStorage('id_token');

export const addPrices = (prices, retailerID, callback) => {
  return async (dispatch) => {
    Promise.all(
      prices.map((price) => {
        if (price.sku !== '' && (price.buyPrice > 0 || price.sellPrice > 0)) {
          let data = JSON.stringify({
            pricing: {
              buying_price: price.buyPrice,
              selling_price: price.sellPrice,
              retailer_id: retailerID,
              sku_product: price.sku,
            },
          });
          const options = {
            method: 'post',
            url: baseUrl + 'kamioun-pricing/pricing',
            headers: {
              Authorization: 'Bearer ' + token,
              'Content-Type': 'application/json',
            },
            data: data,
          };
          axios(options)
            .then((response) => {
              let data = response.data;
              dispatch({ type: ADD_PRICE, data });
            })
            .catch((error) => handleApiErrors(error));
        }
      }),
    )
      .then(() => {
        let points = 0;
        prices.map((price) => {
          if (!isNaN(price.buyPrice) && price.buyPrice > 0) points++;
          if (!isNaN(price.sellPrice) && price.sellPrice > 0) {
            points++;
          }
        });
        callback(points);
      })
      .catch((error) => handleApiErrors(error));
  };
};
