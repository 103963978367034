import { CREATE_DOC, DOC_SIGNED } from '../actions/types';

const initialState = {
  docID: '',
  isDocSigned: false,
};

export const signatureReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_DOC:
      return { ...state, docID: action.data };
    case DOC_SIGNED:
      return { isDocSigned: action.signed };
  }

  return state;
};
