import { GET_MANUFACTURERS, UPDATE_MANUFACTURER_BLS } from '../actions/types';

const initialState = {
  manufacturers: { items: [] },
};

export const manufacturersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MANUFACTURERS:
      return { ...state, manufacturers: action.data };
    case UPDATE_MANUFACTURER_BLS:
      return { ...state, manufacturers: { items: action.updatedList } };
  }
  return state;
};
