import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
// styles
import useStyles from './styles';

// components
import MUIDataTable from 'mui-datatables';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import {
  Grid,
  Typography,
  TableFooter,
  TableCell,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  TablePagination,
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CircularProgress from '@material-ui/core/CircularProgress';

// Routing
import { useHistory } from 'react-router-dom';

//Redux Logic
import { useSelector, useDispatch } from 'react-redux';
import {
  loadCategories,
  selectCategorie,
  loadDataPoints,
  changeDataPointsPage,
  changeDataPointsRowsPerPage,
} from '../../redux/actions/dataPoints';

export default function PriceDashboard() {
  let classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const dataPointsState = useSelector((state) => state).dataPointsReducer;
  const uiState = useSelector((state) => state).uiActionsReducer;
  const columns = [
    {
      name: 'SKU',
    },
    {
      name: 'Zone',
    },
    {
      name: 'Retailer',
    },
    {
      name: 'Buying price',
    },
    {
      name: 'Selling price',
    },
    {
      name: 'Date of report',
    },
  ];

  useEffect(() => {
    dispatch(loadCategories(false, dataPointsState.page, dataPointsState.itemsPerPage));
    dispatch(
      loadDataPoints(
        dataPointsState.pageDataPoints,
        dataPointsState.itemPerPageDataPoints,
        dataPointsState.selectedCategorie,
      ),
    );
  }, []);

  const loadMoreCategories = (event) => {
    //Change Page
    //Append it to existing page
    let isBottomReached =
      Math.round(event.target.scrollHeight - 0) <=
      Math.round(event.target.scrollTop + event.target.clientHeight);
    if (isBottomReached) {
      if (dataPointsState.categories.length < dataPointsState.total) {
        dispatch(loadCategories(true, dataPointsState.page + 1, dataPointsState.itemsPerPage));
      }
    }
  };

  //****HANDLERS****
  const handleCategorieClick = (idCategorie) => {
    dispatch(selectCategorie(idCategorie));
    dispatch(loadDataPoints(1, 10, idCategorie));
  };
  const handleChangePage = (evt, newPage) => {
    dispatch(
      changeDataPointsPage(
        newPage,
        dataPointsState.itemPerPageDataPoints,
        dataPointsState.selectedCategorie,
      ),
    );
  };
  const handleChangeRowsPerPage = (evt) => {
    if (dataPointsState.itemPerPageDataPoints !== parseInt(evt.target.value, 10)) {
      let rowsNb = parseInt(evt.target.value, 10);
      dispatch(changeDataPointsRowsPerPage(rowsNb, dataPointsState.selectedCategorie));
    }
  };
  const options = {
    expandableRowsOnClick: true,
    responsive: 'standard',
    filterType: 'dropdown',
    filter: false,
    download: false,
    print: false,
    serverSide: true,
    search: false,
    viewColumns: false,
    /*pagination: dataPointsState.totalDataPoints > 0 && true,
    page: dataPointsState.pageDataPoints,
    rowsPerPage: dataPointsState.itemPerPageDataPoints,
    count: dataPointsState.totalDataPoints,*/
    textLabels: {
      body: {
        noMatch: uiState.isSecLoading ? (
          <CircularProgress />
        ) : (
          'Sorry, there is no matching data points to display'
        ),
      },
    },
    customToolbar: () => {
      let selectedCategorie = dataPointsState.selectedCategorie;
      let categories = dataPointsState.categories;
      return (
        <div>
          <div className={classes.box}>
            {categories.length > 0 && dataPointsState.total < 6 ? (
              categories.map((category) => (
                <Button
                  variant="outlined"
                  color={selectedCategorie == category.id ? 'primary' : 'default'}
                  className={classes.btn_filter}
                  onClick={() => {
                    handleCategorieClick(category.id);
                  }}
                >
                  {category.name}
                  {selectedCategorie == category.id ? (
                    <span>
                      <DoneIcon className={classes.doneIcon} />
                    </span>
                  ) : null}
                </Button>
              ))
            ) : (
              <FormControl variant="outlined" style={{ width: '30%' }}>
                <InputLabel
                  style={{
                    backgroundColor: 'white',
                    paddingLeft: 2,
                    paddingRight: 2,
                  }}
                  htmlFor="categories"
                >
                  Categories
                </InputLabel>
                <Select
                  value={dataPointsState.selectedCategorie}
                  onChange={(evt) => {
                    handleCategorieClick(evt.target.value);
                  }}
                  MenuProps={{
                    onScroll: loadMoreCategories,
                  }}
                >
                  <MenuItem value={0} style={{ textAlign: 'center' }}>
                    All
                  </MenuItem>
                  {categories.map((cat) => (
                    <MenuItem value={cat.id} style={{ textAlign: 'center' }}>
                      {cat.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </div>
        </div>
      );
    },
    customFooter: () => {
      return (
        <TableFooter>
          {dataPointsState.totalDataPoints > 0 && (
            <TableRow>
              <TableCell align="right">
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  component="div"
                  count={dataPointsState.totalDataPoints}
                  page={dataPointsState.pageDataPoints}
                  rowsPerPage={dataPointsState.itemPerPageDataPoints}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <Typography variant="h6" color="primary" className={classes.dataPointsValue}>
                  {dataPointsState.totalDataPoints + '  datapoints'}
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableFooter>
      );
    },
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MUIDataTable
            title={
              <Typography variant="h4" className={classes.title}>
                Data Price points
                {uiState.isLoading && (
                  <>
                    <CircularProgress
                      size={24}
                      style={{ marginLeft: 15, position: 'relative', top: 4 }}
                    />
                  </>
                )}
              </Typography>
            }
            data={
              uiState.isSecLoading
                ? []
                : dataPointsState.dataPoints.map((item) => {
                    let name = item.retailer_name ? item.retailer_name.split(/[ ]+/) : [];
                    return [
                      item.product_name ? item.product_name : 'Product name Missing',
                      item.zone,
                      name.length > 0
                        ? name[0] + ' ' + name[1].substr(0, 1).toUpperCase() + '.'
                        : '',
                      moment(item.date_repport).format('ddd, DD/MM/YYYY'),
                      item.buying_price,
                      item.selling_price,
                    ];
                  })
            }
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>
    </>
  );
}
