import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  parentContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  widgetRoot: {
    boxShadow: theme.customShadows.widget,
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: '1.5em',
    fontWeight: '700',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.75,
    letterSpacing: '0.15px',
    textAlign: 'left',
    color: '#263238',
  },
  status: {
    fontFamily: 'Roboto',
    fontSize: '1.4em',
    fontWeight: 500,
    fontStretch: 'normal',
    textTransform: 'capitalize ',
    fontStyle: 'normal',
    lineHeight: 1.75,
    margin: '-4px 0px 0px 12px',
    letterSpacing: '0.15px',
    textAlign: 'left',
    color: '#e0b20e',
  },
  rowDirection: {
    display: 'flex',
    flexDirection: 'row',
  },
  formContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  sectionReport: {
    marginTop: theme.spacing(4),
  },
  agentComment: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    minWidth: 120,
    position: 'relative',
    marginTop: 20,
    marginRight: theme.spacing(1),
    marginLeft: 'auto',
    background: 'transparent',
    fontWeight: 100,
    cursor: 'pointer',
    borderRadius: 6,
  },
  modalPaper: {
    position: 'absolute',
    width: '50%',
    maxWidth: '90%',
    backgroundColor: 'white',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxHeight: '80vh',
    overflow: 'auto',
  },
  box: {
    float: 'left',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignSelf: 'flew-end',
    top: 25,
    bottom: 20,
    left: 'auto',
    right: 'auto',
    marginTop: 10,
    marginBottom: 15,
    '& > *': {
      margin: theme.spacing(2),
    },
  },
  btnDownloadReport: {
    float: 'right',
    position: 'relative',
    marginTop: 20,
    marginRight: 'auto',
    marginLeft: 'auto',
    border: 'none',
    background: '#2E466E',
    color: '#ffffff',
    fontWeight: 100,
    padding: 20,
    cursor: 'pointer',
    textTransform: 'uppercase',
    borderRadius: 6,
    boxShadow: '2.2px 6px 10px rgba(0, 0, 0, 0.15)',
    transition: 'all 0.3s ease 0s',
  },
  btn_create: {
    float: 'right',
    position: 'relative',
    marginTop: 20,
    marginRight: 'auto',
    marginLeft: 'auto',
    border: 'none',
    background: '#ffe07a',
    color: '#ffffff',
    fontWeight: 100,
    padding: 20,
    cursor: 'pointer',
    textTransform: 'uppercase',
    borderRadius: 6,
    boxShadow: '2.2px 6px 10px rgba(0, 0, 0, 0.15)',
    transition: 'all 0.3s ease 0s',
  },
  datePicker: {
    position: 'relative',
    marginTop: 20,
    marginRight: theme.spacing(1),
    background: 'transparent',
    fontWeight: 100,
    padding: 20,
    cursor: 'pointer',
    textTransform: 'uppercase',
    borderWidth: 1,
    borderColor: 'blue',
    borderRadius: 6,
  },
  btn_pickList: {
    float: 'right',
    position: 'relative',
    marginTop: 20,
    marginRight: theme.spacing(1),
    marginLeft: 'auto',
    background: 'transparent',
    color: 'grey',
    fontWeight: 100,
    padding: 20,
    cursor: 'pointer',
    textTransform: 'uppercase',
    borderWidth: 1,
    borderColor: 'rgba(0,0,0,0.3)',
    borderRadius: 6,
  },
  btn_salesList: {
    float: 'right',
    position: 'relative',
    marginTop: 20,
    marginRight: theme.spacing(1),
    marginLeft: 'auto',
    background: 'transparent',
    color: 'grey',
    fontWeight: 100,
    padding: 20,
    cursor: 'pointer',
    textTransform: 'uppercase',
    borderWidth: 1,
    borderColor: 'rgba(0,0,0,0.3)',
    borderRadius: 6,
  },
  doneIcon: {
    height: 20,
    width: 20,
    marginTop: 0,
    marginBottom: -5,
    marginLeft: 10,
  },
  blStatus: {
    borderRadius: 14,
    padding: 7,
    fontSize: 11,
  },
  btn_filter: {
    borderRadius: 50,
    fontSize: 13,
    textTransform: 'normal',
    marginTop: 20,
    marginBottom: 27,
  },
  card: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  visitsNumberContainer: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    paddingBottom: theme.spacing(1),
  },
  progressSection: {
    marginBottom: theme.spacing(1),
  },
  progressTitle: {
    marginBottom: theme.spacing(2),
  },
  progress: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  pieChartLegendWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginRight: theme.spacing(1),
  },
  legendItemContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  fullHeightBody: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  tableWidget: {
    overflowX: 'auto',
  },
  progressBar: {
    backgroundColor: theme.palette.warning.main,
  },
  performanceLegendWrapper: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  legendElement: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
  legendElementText: {
    marginLeft: theme.spacing(1),
  },
  serverOverviewElement: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
  },
  serverOverviewElementText: {
    minWidth: 145,
    paddingRight: theme.spacing(2),
  },
  serverOverviewElementChartWrapper: {
    width: '100%',
  },
  mainChartBody: {
    overflowX: 'auto',
  },
  mainChartHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.only('xs')]: {
      flexWrap: 'wrap',
    },
  },
  mainChartHeaderLabels: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      order: 3,
      width: '100%',
      justifyContent: 'center',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
  },
  mainChartHeaderLabel: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(3),
  },
  mainChartSelectRoot: {
    borderColor: theme.palette.text.hint + '80 !important',
  },
  picklistContainer: {
    flexDirection: 'row',
    display: 'flex',
    position: 'relative',
    float: 'right',
    zIndex: 101,
  },
  mainChartSelect: {
    padding: 10,
    paddingRight: 25,
  },
  mainChartLegentElement: {
    fontSize: '18px !important',
    marginLeft: theme.spacing(1),
  },
  editButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  kamiounIdInput: {},
  total: {
    fontWeight: 700,
  },
  secTitle: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
  },
  reportTitle: {
    fontWeight: 'bold',
    color: '#314168',
  },
  noBrder: {
    border: 'none',
  },
  shippedInput: {
    border: 'none',
    width: 20,
  },
  withborder: {
    borderTop: '1px solid rgba(0,0,0,0.1)',
    borderBottom: 'none',
  },
  modalCloseButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  modalTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  orderDetails: {
    minHeight: 400,
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.4)',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[5],
    borderRadius: 14,
    padding: theme.spacing(2, 4, 3),
    height: 'auto',
    width: '90%',
    outline: 'none',
    [theme.breakpoints.up('sm')]: {
      width: '70%',
    },
  },
  paperReports: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    height: 'auto',
    width: '40%',
    outline: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  orderHeaders: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '& > div': {
      width: '100%',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        width: 'calc(10% - 10px)',
        marginRight: theme.spacing(0),
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  productTable: {
    marginTop: 27,
  },
  orderAttributes: {
    fontSize: '1.3rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem',
    },
  },
  orderInfos: {
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6rem',
    },
  },
  changeStatusButton: {
    margin: theme.spacing(1),
    color: '#314168',
    '&:hover': {
      background: '#314168',
      color: '#fff',
    },
  },
  printButton: {
    alignSelf: 'center',
    marginTop: 20,
    marginLeft: 10,
    marginRight: 17,
    cursor: 'pointer',
  },
  cancelButton: {
    margin: theme.spacing(1),
    color: 'red',
    '&:hover': {
      background: 'red',
      color: '#fff',
    },
  },
  editOrder: {
    height: '30%',
    alignSelf: 'center',
  },
  iconsContainer: {
    display: 'flex',
  },
  pdfIcon: {
    length: 0,
  },
  logotypeIcon: {
    width: 30,
  },
  modalLoader: {
    margin: theme.spacing(1),
  },
  deliveryStatus: {
    height: '40px',
    width: '90%',
    color: '#263238',
    fontFamily: 'Roboto',
    fontWeight: '500',
    textTransform: 'uppercase',
    fontSize: 17,
    paddingTop: 8,
    textAlign: 'center',
    borderRadius: 6,
    boxShadow:
      '0 1px 8px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.16)',
  },
  cancelModalContainer: {
    minWidth: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    border: 'none',
    backgroundColor: '#fff',
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    outline: 'none',
  },
  warningIcon: {
    color: '#E74C3C',
    height: 80,
    width: 78,
    margin: 0,
  },
  confirmMessage: {
    fontFamily: 'Cairo',
    fontStyle: 'normal',
    fontWeight: 'bold',
    margin: 0,
    fontSize: 36,
    textAlign: 'center',
    color: '#E74C3C',
  },
  message: {
    fontFamily: 'Cairo',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 18,
    margin: 0,
    textAlign: 'center',
    color: '#2E466E',
  },
  buttonsContainer: {
    width: '100%',
    textAlign: 'right',
    margin: 10,
  },
  confirmButton: {
    backgroundColor: '#2E466E',
    color: '#fff',
    fontFamily: 'Cairo',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 12,
    margin: 7,
    textAlign: 'center',
    boxShadow:
      '0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1)',
    '&:hover, &:focus': {
      color: '#2E466E',
    },
  },
  deleteCancel: {
    backgroundColor: '#F1F1F1',
    color: '#2E466E',
    fontFamily: 'Cairo',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 12,
    textAlign: 'center',
    boxShadow:
      '0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1)',
  },
  idStyle: {
    fontFamily: 'Cairo',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 18,
    textAlign: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    color: '#263238',
  },
}));
