//TODO: #ORDERS# REDUCER
import {
  SELECT_DELIVERY_DATE,
  SELECT_PRODUCT,
  EDIT_SELECTED_PRODUCT,
  UPDATE_TOTAL,
  CREATE_ORDER,
  CLEAR_FORM,
  DELETE_PRODUCT,
  GET_ORDERS,
  UPDATE_ORDERS_STATUS,
  GENERATE_BL_SINGLE_ORDER,
  GET_ALL_BLS,
  GET_ORDER_BY_ID,
  GET_BLS_FOR_ORDER,
  GET_BL_BY_ID,
  CHANGE_ORDERS_PAGE,
  CHANGE_ORDERS_PER_PAGE,
  SWITCH_FILTER_ORDERS,
  IS_CHANGING_ORDERS_STATUS,
  REMOVE_ORDER_IN_LIST,
  LOAD_ORDERS_DELIVERY_DATE,
  SELECT_PICKLIST_DATE,
  GENERATE_PICKLIST,
  UPDATE_ORDERS_DETAILS,
  UPDATING_ORDER,
  EDIT_ORDER,
  LOADING_REPORT,
  GET_AGENT_REPORT,
  GET_SALES_LIST,
  GET_ORDERS_BY_DATE,
  SELECT_ORDER_ON_MAP,
  GET_ORDERS_PART,
  CLEAR_ORDERS,
} from '../actions/types';
import moment from 'moment';

const initialState = {
  selectedDate: moment().format('YYYY-MM-DD'),
  selectedProducts: [],
  selectedRows: [],
  page: 0,
  rowsPerPage: 50,
  totalOrders: 0,
  total: 0,
  orders: { items: [] },
  ordersByDate: false,
  allOrdersBls: [],
  generatedOrder: null,
  generatedOrderBls: null,
  selectedBl: null,
  selectedFilter: 'open',
  isChangingOrderStatus: false,
  DeliveryDateList: [],
  selectedPicklistDate: '',
  generatedPickList: [],
  updatingOrderloader: false,
  editOrder: false,
  loadingReport: false,
  agentReport: null,
  salesList: null,
};

export const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_ORDER:
      return { ...state, editOrder: action.editOrder };
    case SELECT_DELIVERY_DATE:
      return { ...state, selectedDate: action.day };
    case SELECT_PRODUCT:
      return {
        ...state,
        selectedProducts: [...state.selectedProducts, action.product],
        selectedRows: [...state.selectedRows, action.selectedRow],
        total: state.total + action.selectedRow.qeCmd * action.selectedRow.prixPcb,
      };
    case EDIT_SELECTED_PRODUCT:
      return {
        ...state,
        selectedProducts: action.updatedList,
        selectedRows: action.updatedRows,
        total: action.total,
      };
    case UPDATE_TOTAL:
      return {
        ...state,
        total: action.total,
      };
    case CREATE_ORDER:
      return { ...state };
    case CLEAR_FORM:
      return {
        ...state,
        selectedDate: moment().format('YYYY-MM-DD'),
        selectedProducts: [],
        selectedRows: [],
        total: 0,
      };
    case DELETE_PRODUCT:
      return {
        ...state,
        selectedProducts: action.SelectedProducts,
        selectedRows: action.SelectedRows,
        total: action.newTotal,
      };
    case GET_ORDERS:
      return { ...state, orders: action.data, totalOrders: action.total };
    case GET_ORDERS_PART:
      return {
        ...state,
        orders: { items: [...state.orders.items, ...action.data.items] },
        totalOrders: action.total,
      };
    case CLEAR_ORDERS:
      return { ...state, orders: { items: [] }, totalOrders: 0 };
    case GET_ORDERS_BY_DATE:
      return { ...state, ordersByDate: action.data };
    case SELECT_ORDER_ON_MAP:
      return { ...state, ordersByDate: action.orders };
    case UPDATE_ORDERS_STATUS:
      return { ...state };
    case UPDATE_ORDERS_DETAILS:
      return { ...state };
    case GENERATE_BL_SINGLE_ORDER:
      return { ...state };
    case GET_ALL_BLS:
      return { ...state, allOrdersBls: action.data };
    case GET_ORDER_BY_ID:
      return { ...state, generatedOrder: action.data };
    case GET_BLS_FOR_ORDER:
      return { ...state, generatedOrderBls: action.data };
    case GET_BL_BY_ID:
      return { ...state, selectedBl: action.data };
    case CHANGE_ORDERS_PAGE:
      return { ...state, page: action.page };
    case CHANGE_ORDERS_PER_PAGE:
      return { ...state, rowsPerPage: action.itemsNb, page: 0 };
    case SWITCH_FILTER_ORDERS:
      return { ...state, selectedFilter: action.filter, page: 0 };
    case IS_CHANGING_ORDERS_STATUS:
      return { ...state, isChangingOrderStatus: action.isChanging };
    case UPDATING_ORDER:
      return { ...state, updatingOrderloader: action.isUpdating };
    case REMOVE_ORDER_IN_LIST:
      return {
        ...state,
        orders: {
          items: state.orders.items.filter((item) => item.entity_id !== action.selectedOrder),
        },
      };
    case LOAD_ORDERS_DELIVERY_DATE:
      return { ...state, DeliveryDateList: action.commingDeliveryDates };
    case SELECT_PICKLIST_DATE:
      return { ...state, selectedPicklistDate: action.selectedDate };
    case GENERATE_PICKLIST:
      return { ...state, generatedPickList: action.filterdList };
    case LOADING_REPORT:
      return { ...state, loadingReport: action.isLoading };
    case GET_AGENT_REPORT:
      return { ...state, agentReport: action.report };
    case GET_SALES_LIST:
      return { ...state, salesList: action.list };
    default:
      return state;
  }
};
