import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  box: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignSelf: 'flew-end',
    left: 'auto',
    right: 'auto',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  btn_filter: {
    borderRadius: 50,
    fontSize: 13,
    textTransform: 'capitalize',
  },
  doneIcon: {
    height: 20,
    width: 20,
    marginTop: 0,
    marginBottom: -5,
    marginLeft: 10,
  },
  rowTypoClicked: {
    color: '#17c2e8d1',
  },
  rowTypo: {
    color: '#a3a3a3',
  },
  container: {
    display: 'block',
  },
  signatureState: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  blsNotSigned: {
    backgroundColor: '#fcf495',
    fontSize: 20,
    color: 'grey',
    padding: 10,
  },
  blsAllSigned: {
    backgroundColor: '#c8f4f7',
    fontSize: 20,
    color: '#15a3cf',
    padding: 10,
  },
  clientTypo: {
    color: '#000',
  },
  oTable: {
    width: '100%',
    tableLayout: 'auto',
  },
  emptyCell: {
    border: 'none',
  },
  blsOrders: {
    width: 'auto',
  },
  pdfIcon: {
    borderBottom: 'none',
    padding: 0,
    paddingTop: 10,
  },
  docIcon: {
    paddingRight: 60,
    borderBottom: 'none',
  },
  retailerName: {
    fontSize: 17,
    borderBottom: 'none',
  },
  blNumber: {
    padding: 0,
    paddingLeft: 10,
    borderBottom: 'none',
  },
  notSigned: {
    paddingRight: 21,
    color: '#f7ad19',
    borderBottom: 'none',
  },
  numberField: {
    padding: 0,
    marginTop: '17px',
    marginBottom: '17px',
  },
  borderedCell: {
    borderTop: '1px solid rgba(0,0,0,0.2)',
    borderBottom: 'none',
  },
  total: {
    color: 'rgba(0,0,0,0.4)',
  },
  totalValue: {
    width: 'auto',
    fontWeight: 'bold',
    fontSize: '17px',
  },
  billingLabel: {
    color: 'rgba(0,0,0,0.4)',
    fontSize: '17px',
  },
  billingSystem: {
    color: '#17c2e8d1',
  },
  clientInfo: {
    marginTop: '10px',
    marginBottom: '30px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  billingInfo: {
    marginTop: '47px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  clientPhone: {
    marginLeft: '20px',
    fontWeight: 'bold',
  },
  contactName: {
    fontSize: '14px',
    marginLeft: '8px',
  },
  btnPrimary: {
    width: '100%',
    backgroundColor: '#FFC260',
    bottom: 0,
    color: '#fff',
    boxShadow: '2.2px 6px 10px rgba(0, 0, 0, 0.15)',
    transition: 'all 0.3s ease 0s',
    '&:hover': {
      background: '#314168',
      color: '#fff',
    },
  },
  loadingCircle: {
    display: 'flex',
    justifyContent: 'center',
    margin: '50px',
  },
  copyButton: {
    marginTop: '-40px',
    marginBottom: '20px',
  },
  clipBoardButton: {
    backgroundColor: 'white',
    border: '1px solid #008CBA',
    boxShadow: '2.2px 6px 10px rgba(0, 0, 0, 0.15)',
    '&:hover': {
      background: '#e7e7e7',
    },
  },
  clipBoardButtonCopied: {
    backgroundColor: 'white',
    border: '1px solid #008CBA',
    boxShadow: '2.2px 6px 10px rgba(0, 0, 0, 0.15)',
    color: '#008CBA',
  },
}));
