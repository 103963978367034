import React, { useEffect, useState } from 'react';

import moment from 'moment';
import DatePicker from 'react-datepicker';
//routing

//styles
import useStyles from './styles';
import {
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  MenuItem,
  CircularProgress,
  Button,
} from '@material-ui/core';
import { setDeliveryAgent, getOrdersByDate } from '../../redux/actions/orders';
import { getAgents } from '../../redux/actions/retailers';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// components
import MapComponent from './map';
//Redux logic

export default function Dispatch() {
  let classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const retailersState = useSelector((state) => state).retailersReducer;
  const uiActionsState = useSelector((state) => state).uiActionsReducer;
  const [deliveryDate, setDeliveryDate] = useState(moment().add(1, 'days').toDate());
  const [deliveryAgent, setAgent] = useState('');
  const [milkRun, setMilkRun] = useState('');
  const [deliveryAgents, setDeliveryAgents] = useState([]);

  useEffect(() => {
    dispatch(getOrdersByDate(moment(deliveryDate).format('DD/MM/YYYY')));
    dispatch(
      getAgents((data) => {
        setDeliveryAgents(data.items);
      }),
    );
  }, []);

  const DatePickerCustomInput = ({ value, onClick }) => (
    <button className={classes.datePicker} onClick={onClick} placeholder="Delivery Date">
      {value}
    </button>
  );

  const onValidate = () => {
    if (deliveryAgent.length === 0) {
      alert('Please select delivery agent');
      return;
    }
    if (milkRun != 'morning' && milkRun != 'afternoon') {
      alert('Please select Milk-Run period');
      return;
    }
    dispatch(setDeliveryAgent(deliveryAgent, milkRun, deliveryDate));
  };

  const onSelectAgent = (agent) => {
    setAgent(agent);
  };

  const onSelectMilkRun = (period) => {
    setMilkRun(period);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.widgetRoot}>
            <Grid container>
              <Grid item xs={12}>
                {uiActionsState.isLoading ? (
                  <Grid item xs={12}>
                    <Paper className={classes.widgetRoot}>
                      <div className={classes.loadingCircle}>
                        <CircularProgress />
                      </div>
                    </Paper>
                  </Grid>
                ) : (
                  <Paper className={classes.widgetRoot}>
                    <MapComponent
                    /*onMarkerClic={onMarkerClic}
                      onMarkerWindowClose={onMarkerWindowClose}
                      markersSelected={markersSelected}
                      orders={ordersState.ordersByDate}*/
                    />
                  </Paper>
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                <div className={classes.picklistContainer}>
                  <FormControl
                    variant="outlined"
                    style={{ width: '80%', minWidth: 100 }}
                    className={classes.sectionReport}
                  >
                    <InputLabel
                      style={{
                        backgroundColor: 'white',
                        paddingLeft: 2,
                        paddingRight: 2,
                      }}
                      htmlFor="Delivery Agent"
                    >
                      Delivery Agent
                    </InputLabel>
                    <Select
                      value={deliveryAgent}
                      onChange={(evt) => {
                        onSelectAgent(evt.target.value);
                      }}
                    >
                      {retailersState.agents &&
                        deliveryAgents.map((item) => (
                          <MenuItem value={item} style={{ textAlign: 'center' }}>
                            {item.firstname + ' ' + item.lastname}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <DatePicker
                    selected={deliveryDate}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => {
                      setDeliveryDate(date);
                      dispatch(getOrdersByDate(moment(date).format('DD/MM/YYYY')));
                    }}
                    placeholderText={'Delivery Date'}
                    customInput={<DatePickerCustomInput />}
                    withPortal
                  />
                  <FormControl
                    variant="outlined"
                    style={{ width: '80%', minWidth: 100 }}
                    className={classes.sectionReport}
                  >
                    <InputLabel
                      style={{
                        backgroundColor: 'white',
                        paddingLeft: 2,
                        paddingRight: 2,
                      }}
                      htmlFor="Milk-Run"
                    >
                      Milk-Run
                    </InputLabel>
                    <Select
                      value={milkRun}
                      onChange={(evt) => {
                        onSelectMilkRun(evt.target.value);
                      }}
                    >
                      <MenuItem value={'morning'} style={{ textAlign: 'center' }}>
                        Morning
                      </MenuItem>
                      <MenuItem value={'afternoon'} style={{ textAlign: 'center' }}>
                        Afternoon
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.formButtons}>
                  <Button
                    type="reset"
                    variant="outlined"
                    className={classes.cancelButton}
                    onClick={() => {
                      history.push('/app/dashboard');
                    }}
                  >
                    Cancel
                  </Button>
                  {uiActionsState.dispatchingLoader ? (
                    <div className={classes.validateButton}>
                      <CircularProgress />
                    </div>
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      className={classes.validateButton}
                      onClick={onValidate}
                    >
                      Validate
                    </Button>
                  )}
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
