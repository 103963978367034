import { UPDATE_SHIPPING_BILLS } from './types';
import axios from 'axios';
import moment from 'moment';
import { getManufacturers } from './manufacturers';
import { createDoc } from './signature';
import { getLocalStorage } from '../../hooks/useLocalStorage';
import { handleApiErrors } from '../../utils/handleApiErrors';

const baseUrl = process.env.REACT_APP_BASE_URL;

const token = getLocalStorage('id_token');

export const updateBlsStatus = (batchBLs, status) => {
  return async (dispatch) => {
    Promise.all(
      batchBLs.shipping_bl.map(async (bl) => {
        const bl_id = bl.url_shipping_bil.slice(
          bl.url_shipping_bil.lastIndexOf('-') + 1,
          bl.url_shipping_bil.length - 4,
        );
        bl.status = status;
        bl.sentondate = moment().format('YYYY-MM-DD');
        let config = {
          method: 'put',
          url: baseUrl + `kamioun-shippingbill/shippingbill/${bl_id}`,
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
          },
          data: { shippingbill: bl },
        };
        await axios(config)
          .then(function () {
            dispatch({ type: UPDATE_SHIPPING_BILLS });
          })
          .catch((error) => handleApiErrors(error));
      }),
    )
      .then(async (res) => {
        if (status === 'signature_missing') {
          await dispatch(getManufacturers());
          await dispatch(createDoc(batchBLs));
        }
      })
      .catch((error) => handleApiErrors(error));
  };
};
