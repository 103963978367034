import { ADD_PRICE } from '../actions/types';

const initialState = {
  price: {},
};

export const pricesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PRICE:
      return { ...state, price: action.data };
  }
  return state;
};
